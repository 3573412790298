import {
  Callout,
  DirectionalHint,
  Stack,
  Label,
  TooltipHost,
} from '@fluentui/react';
import { INavGroup, INavItem } from 'nav/navGroups';
import React, { useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { NavItem } from './NavItem';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { useId } from '@fluentui/react-hooks';
import { useCommonStyles } from 'common/styles';
import { useReactiveVar } from '@apollo/client';
import { globalMode, setGlobalSearchText } from 'utility/cache/ui';
import { setPanelWidth } from 'common/components/DraggablePanel';

interface NavGroupProps {
  data: INavGroup;
  expanded: boolean;
}

export const NavGroup: React.FC<NavGroupProps> = ({ data, expanded }) => {
  const location = useLocation();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const parentId = useId();
  const globalState = useReactiveVar(globalMode);
  const { name, path, exact, Icon, items } = data;

  const isParentActive = !!(
    path && matchPath(location.pathname, { path, exact })
  );
  const activeChild =
    items &&
    items.find((item) =>
      matchPath(location.pathname, { path: item.path, exact: item.exact })
    );

  return (
    <Stack
      className={clsx((isParentActive || activeChild) && styles.activeGroup)}
    >
      <NavItem
        navItemProps={data as INavItem}
        id={parentId}
        text={name}
        to={path ?? items![0].path!}
        expanded={expanded}
        Icon={Icon}
        active={isParentActive}
        bold={isParentActive || !!activeChild}
        onMouseEnter={() => {
          if (items && !isParentActive && !activeChild)
            setIsCalloutVisible(true);
        }}
        onLinkClick={() => {
          if (globalState) globalMode(false);
          setGlobalSearchText('');
          setPanelWidth(undefined);
        }}
        onMouseLeave={() => setIsCalloutVisible(false)}
      />
      {isCalloutVisible && (
        <Callout
          isBeakVisible={false}
          directionalHint={DirectionalHint.rightTopEdge}
          target={`#${parentId}`}
          calloutMinWidth={200}
          onMouseEnter={() => setIsCalloutVisible(true)}
          onMouseLeave={() => setIsCalloutVisible(false)}
        >
          <Stack className={styles.calloutRoot}>
            <Label className={clsx(styles.calloutTitle, commonStyles.semibold)}>
              {name}
            </Label>
            {items?.map((item) => (
              <NavItem
                navItemProps={item}
                key={item.name}
                text={item.name}
                to={item.path!}
                expanded={true}
                Icon={item.Icon}
                smallIcon
                compact
                onLinkClick={() => {
                  if (globalState) globalMode(false);
                  setIsCalloutVisible(false);
                  setGlobalSearchText('');
                  setPanelWidth(undefined);
                }}
              />
            ))}
          </Stack>
        </Callout>
      )}
      {!!activeChild &&
        items?.map((item) => {
          const itemComponent = (
            <NavItem
              navItemProps={item}
              key={item.name}
              text={item.name}
              to={item.path!}
              expanded={expanded}
              Icon={item.Icon}
              active={activeChild === item}
              bold={activeChild === item}
              smallIcon
              onLinkClick={() => {
                if (globalState) globalMode(false);
                setGlobalSearchText('');
              }}
            />
          );
          if (!expanded) {
            return (
              <TooltipHost
                key={item.name}
                content={item.name}
                calloutProps={{ isBeakVisible: false }}
                directionalHint={DirectionalHint.rightTopEdge}
              >
                {itemComponent}
              </TooltipHost>
            );
          }
          return itemComponent;
        })}
    </Stack>
  );
};
