import { useReactiveVar } from '@apollo/client';
import { AppRouter } from 'common/types/globalTypes';
import React from 'react';
import { SettingsBase, SettingsOption } from 'settings';
import { setUserPrivileges } from 'utility/cache/ui';
import { AccountingPeriodEntry } from './accountingPeriodEntry/list';
import { CommunicationChannel } from './communicationChannels/list';
import { CommunicationGroup } from './communicationGroup/list';
import { PurchaseCards } from './purchaseCards/list';
import { TagCategorySetup } from './tagCategorySetup/list';
import { TagSetup } from './tagSetup/list';
import { TagsGroupSetup } from './tagsGroupSetup/list';

const SETTINGS_PATH = '/project-settings';
export const sections: SettingsOption[] = [
  {
    name: 'Purchase Cards',
    path: `${SETTINGS_PATH}/purchase-cards`,
    component: PurchaseCards,
    routeName: AppRouter.PCARD_ACCOUNT,
    icon: 'PaymentCard',
  },
  {
    name: 'Channels',
    path: `${SETTINGS_PATH}/channels`,
    component: CommunicationChannel,
    routeName: AppRouter.CHANNELS,
    icon: 'CommentNext',
  },
  {
    name: 'Communication Groups',
    path: `${SETTINGS_PATH}/groups`,
    component: CommunicationGroup,
    routeName: AppRouter.COMMUNICATION_GROUPS,
    icon: 'CommentNext',
  },
  {
    name: 'Tags',
    path: `${SETTINGS_PATH}/tags`,
    component: TagSetup,
    routeName: AppRouter.TAGS,
    icon: 'Tag',
  },
  {
    name: 'Tag Categories',
    path: `${SETTINGS_PATH}/tag-categories`,
    component: TagCategorySetup,
    routeName: AppRouter.TAG_CATEGORIES,
    icon: 'TagUnknown',
  },
  {
    name: 'Tag Groups',
    path: `${SETTINGS_PATH}/tag-groups`,
    component: TagsGroupSetup,
    routeName: AppRouter.TAG_GROUPS,
    icon: 'TagSolid',
  },
  {
    name: 'Accounting Periods',
    path: `${SETTINGS_PATH}/accounting-periods`,
    component: AccountingPeriodEntry,
    icon: 'CalendarSettings',
    routeName: AppRouter.ACCOUNTING_PERIOD,
  },
];

export const ProjectSettings: React.FC = () => {
  const privileges = useReactiveVar(setUserPrivileges);

  const sectionsList: SettingsOption[] = sections.filter(
    (item) => privileges.has(item.routeName!) || item.routeName === undefined
  );

  return (
    <SettingsBase
      basePath={SETTINGS_PATH}
      sections={sectionsList}
      title="Project Settings"
    />
  );
};
