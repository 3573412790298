import { useMutation, useQuery } from '@apollo/client';
import {
  ActionButton,
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ManageApproversProps } from '..';
import {
  AvailableApprovalApproverList,
  AvailableApprovalApproverListVariables,
} from '../__generated__/AvailableApprovalApproverList';
import {
  ReplaceApproverApprovals,
  ReplaceApproverApprovalsVariables,
} from '../__generated__/ReplaceApproverApprovals';
import { ExceptionModal } from '../Exception';
import { useStyles } from './index.styles';
const MUTATION = loader('./../ReplaceApproverApprovals.graphql');
const AVAILABLE_APPROVAL_APPROVER_LIST = loader(
  '../AvailableApprovalApproverList.graphql'
);
export const ReplaceApprover: React.FC<
  Pick<ManageApproversProps, 'selectedRows' | 'approverUser'> & {
    onSuccess: () => void;
  }
> = (props) => {
  const styles = useStyles();
  const { addToast } = useToasts();
  const [visible, setVisible] = useState(false);
  const [newApprover, setNewApprover] = useState<string | undefined>(undefined);
  const [showErrorResponse, setShowErrorResponse] = useState(false);
  const { data: availableApprovers } = useQuery<
    AvailableApprovalApproverList,
    AvailableApprovalApproverListVariables
  >(AVAILABLE_APPROVAL_APPROVER_LIST, {
    variables: { originalUserId: props.approverUser?.id! },
  });
  const [reason, setReason] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!visible) {
      setNewApprover(undefined);
      setReason(undefined);
    }
  }, [visible]);

  // MUTATION
  const [replaceApprover, { data: response }] = useMutation<
    ReplaceApproverApprovals,
    ReplaceApproverApprovalsVariables
  >(MUTATION);
  const onDismiss = () => {
    setVisible(false);
  };

  const approverListOptions: IDropdownOption[] =
    availableApprovers?.availableApprovalApproverList?.nodes?.map((item) => ({
      key: item.id,
      text: item?.name ?? '',
    })) || [];

  const _onConfirm = async () => {
    setVisible(false);
    const entityApprovalIds = props.selectedRows.map((item) => item.id);

    const { errors, data } = await replaceApprover({
      variables: {
        input: {
          originalUserId: props.approverUser?.id!,
          entityApprovalIds: entityApprovalIds,
          newUserProfileId: newApprover,
          isBypassOnly: false,
          reason: reason || '',
        },
      },
      awaitRefetchQueries: true,
    });

    if (!!data?.replaceApproverApprovals?.processRequests?.length) {
      setShowErrorResponse(true);
    }
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      if (
        data?.replaceApproverApprovals?.processRequests?.length ===
        entityApprovalIds.length
      ) {
        addToast('Approvers could not be replaced', {
          appearance: 'error',
        });
      } else {
        addToast(`Approvers replaced successfully`, {
          appearance: 'success',
        });
        if (!data?.replaceApproverApprovals?.processRequests?.length) {
          props.onSuccess();
        }
      }
    }
  };

  return (
    <Stack>
      <TooltipHost content={'Replacing approvers'}>
        <ActionButton
          iconProps={{ iconName: 'FollowUser' }}
          primary
          text="Replace"
          onClick={() => {
            setVisible(true);
          }}
        />
      </TooltipHost>
      <ConfirmDialog
        isConfirmPrimaryButton
        successBtnDisable={newApprover === undefined || !!!reason?.length}
        hidden={!visible}
        title={`Replace approver - ${props.approverUser?.name}`}
        onDismiss={onDismiss}
        minWidth={450}
        onConfirm={_onConfirm}
      >
        <Stack tokens={{ childrenGap: '10px', padding: '20px 0px 20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Label className={styles.width100}>New Approver </Label>
            <Dropdown
              placeholder="Select"
              className={styles.dropdownStyles}
              options={approverListOptions || []}
              onChange={(_, option) => {
                setNewApprover(option?.key as string);
              }}
            />
          </Stack>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Label className={styles.width100}>Reason for Replacement</Label>
            <TextField
              className={styles.textInputStyle}
              multiline
              rows={3}
              value={reason}
              placeholder="Please write your reason here"
              resizable={false}
              onChange={(_event, value) => setReason(value)}
            />
          </Stack>
        </Stack>
      </ConfirmDialog>

      <ExceptionModal
        showErrorResponse={showErrorResponse}
        response={response}
        onDismiss={() => {
          props.onSuccess();
          setShowErrorResponse(false);
        }}
      />
    </Stack>
  );
};
