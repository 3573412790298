import { NetworkStatus, useQuery, useReactiveVar } from '@apollo/client';
import {
  IColumn,
  Icon,
  IconButton,
  SelectionMode,
  Stack,
  Sticky,
  StickyPositionType,
  Text,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { DocumentViewModalState } from 'common/components/DocumentList';
import { DocumentViewModal } from 'common/components/DocumentList/DocumentViewModal';
import { DownloadButton } from 'common/components/DownloadButton';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import { useCommonStyles } from 'common/styles';
import {
  AppRouter,
  EntityDocumentsOrderBy
} from 'common/types/globalTypes';
import { getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { fileType, fileTypeColor } from 'common/utils/fileType';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { setGlobalSearchText } from 'utility/cache/ui';
import { Tabs } from '../Tabs';
import { UserApps } from '../folder/list/__generated__/UserApps';
import { DocumentsSearchBar } from './DocumentsSearchBar';
import { MultipleDownload } from './MultipleDownload';
import { FileCabinetCommonData } from './__generated__/FileCabinetCommonData';
import {
  FileCabinetDocumentSearch,
  FileCabinetDocumentSearchVariables,
} from './__generated__/FileCabinetDocumentSearch';
import { useColumns } from './columns.data';
import { useStyles } from './index.styles';
import { DocumentListItem } from './interface';
import {
  FilterVariableType,
  toFilterVariable,
  toOrderByVariable,
} from './utils';

const FILE_CABINET_DOCUMENT_SEARCH = loader(
  './FileCabinetDocumentSearch.graphql'
);
const FILE_CABINET_COMMON_DATA = loader('./FileCabinetCommonData.graphql');
const USER_APPS = loader('../folder/list/UserApps.graphql');

export const Documents = () => {
  let listData: DocumentListItem[] = [];
  const { columns } = useColumns();
  const { data: userAppsData } = useQuery<UserApps>(USER_APPS);
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const renderRef = useRef(false);
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [docViewState, setDocViewState] = useState<DocumentViewModalState>({
    isOpen: false,
    _fileType: 'pdf',
  });
  const globalSearchText = useReactiveVar(setGlobalSearchText);

  const { data: fileCabinetCommonData } = useQuery<FileCabinetCommonData>(
    FILE_CABINET_COMMON_DATA,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [selectedList, setSelectedList] = useState<DocumentListItem[]>([]);

  const {
    data,
    loading,
    networkStatus,
    refetch,
    fetchMore,
    variables: fileCabinetDocumentSearchVariables,
  } = useQuery<FileCabinetDocumentSearch, FileCabinetDocumentSearchVariables>(
    FILE_CABINET_DOCUMENT_SEARCH,
    {
      variables: {
        isSigningSearch: false,
        isLinkSearch: false,
        first: TABLE_ROWS,
        orderBy: [EntityDocumentsOrderBy.PRIMARY_KEY_ASC],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const refetching = loading && networkStatus !== NetworkStatus.fetchMore;
  const transformedData = refetching
    ? undefined
    : data?.fileCabinetDocumentSearch?.nodes.map(
        (ele) =>
          ({
            ...ele,
            key: ele.id,
            isoCode: ele.currency?.isoCode,
            indexTransactionDate: ele.indexTransactionDate
              ? dateFormat(dateConvertions(ele.indexTransactionDate))
              : null,
          } as DocumentListItem)
      );
  if (transformedData) listData = transformedData;

  const _renderItemColumn = (
    item: DocumentListItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item[
        column?.fieldName as keyof DocumentListItem
      ] as string;

      switch (column?.key) {
        case 'documentType':
          return (
            <Stack
              tokens={{ padding: '0px 0px 0px 20px' }}
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighLightActiveLink
                to={`/doc/fileCabinet/document/${item.id}`}
                highlightText={globalSearchText}
                text={item?._documentType!}
              />
            </Stack>
          );
           case 'businessUnitName':
          return (
               <Stack
                className={styles.onrenderColumnStack}
                verticalAlign="center"
              >
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={item?._linkedInfo?.businessUnitName||""}
                />
              </Stack>
          );
           case 'departmentName':
          return (
            <Stack
              className={styles.onrenderColumnStack}
              verticalAlign="center"
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={item?._linkedInfo?.departmentName||""}
              />
            </Stack>
          );
        case '_exportDate':
        case '_createdDate':
          if (fieldContent)
            return (
              <Stack
                className={styles.onrenderColumnStack}
                verticalAlign="center"
              >
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={getGlobalDateFormat(fieldContent || '')}
                />
              </Stack>
            );
          else return null;
        case 'indexAmount':
          return (
            <Stack
              verticalAlign="center"
              className={clsx(styles.textAlignEnd, styles.onrenderColumnStack)}
            >
              <AmountColumnTextView
                value={fieldContent}
                searchText={globalSearchText}
              />
            </Stack>
          );

        case 'fileReference':
          return (
            <Stack
              verticalAlign={'center'}
              horizontal
              className={styles.onrenderColumnStack}
              tokens={{ childrenGap: 10 }}
            >
              <Icon
                className={fileTypeColor(item.iconType || 'OTHER')}
                iconName={fileType(item.iconType || 'OTHER')}
              />
              <HighlightTextView
                highlightText={globalSearchText}
                text={fieldContent}
              />
            </Stack>
          );
        case 'view':
          const viewDocumentDisable =
            item._isProtected! || item._fileViewer !== 'browser';
          return (
            <Stack
              className={styles.columnHeight}
              tokens={{ childrenGap: 10 }}
              horizontal
              verticalAlign="center"
            >
              <TooltipHost content="View" id="tooltipId">
                <IconButton
                  disabled={viewDocumentDisable}
                  iconProps={{ iconName: 'View' }}
                  onClick={() =>
                    setDocViewState({
                      isOpen: true,
                      title: item.fileReference,
                      entityDocumentId: item.id,
                      _fileType: item._fileType!,
                    })
                  }
                />
              </TooltipHost>
            </Stack>
          );
        case 'download':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
              horizontalAlign={'center'}
            >
              <DownloadButton entityDocumentId={item.id} />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };
  const _onColumnClick = useCallback(
    async (_ev?: React.MouseEvent<HTMLElement>, clickedColumn?: ColumnData) => {
      if (clickedColumn) {
        const { newColumns, desc } = getSortedColumns(
          clickedColumn,
          gridColumns
        );
        setGridColumns(newColumns);
        setSortOrderParam({
          column: clickedColumn.key,
          direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
        });
      }
    },
    [gridColumns]
  );

  const handleSearch = (showMore: boolean) => {
    const filterVariable: FilterVariableType = toFilterVariable(
      searchFilters ?? []
    );
    const variables: FileCabinetDocumentSearchVariables = {
      fileCabinetDocumentSearchRequest: searchValue
        ? { searchText: [searchValue] }
        : null,
      first: TABLE_ROWS,
      after: showMore
        ? data?.fileCabinetDocumentSearch?.pageInfo.endCursor
        : undefined,
      ...filterVariable,
      orderBy: toOrderByVariable(sortOrderParam!),
    };
    if (showMore) fetchMore?.({ variables });
    else refetch(variables);
  };

  const handleSearchMemo = useCallback(handleSearch, [
    searchFilters,
    sortOrderParam,
    searchValue,
  ]);

  useEffect(() => {
    if (renderRef.current) {
      handleSearchMemo(false);
    } else renderRef.current = true;
  }, [handleSearchMemo]);

  const isMultiDocExport = (userAppsData?.userApps?.nodes || []).findIndex(
    (item) => item.linkType === AppRouter.DOCUMENT_EXPORT
  );

  const allowMultiDocExport =
    isMultiDocExport !== -1 && selectedList.length > 0;

  return (
    <>
      <Sticky stickyPosition={StickyPositionType.Header}>
        <Stack
          tokens={{ childrenGap: 20 }}
          className={commonStyles.listHeaderContainer}
        >
          <Stack
            horizontal
            horizontalAlign="space-between"
            verticalAlign="center"
            className={commonStyles.listTitleContainer}
          >
            <Text variant="xLarge">File Cabinet - Documents</Text>
            <Stack
              horizontal
              verticalAlign="center"
              tokens={{
                childrenGap: 10,
              }}
            >
              {allowMultiDocExport && (
                <MultipleDownload
                  selectedList={selectedList}
                  searchFilters={searchFilters}
                  fileCabinetDocumentSearchRequest={
                    searchValue ? { searchText: [searchValue] } : null
                  }
                  isSelectedAll={listData.length === selectedList.length}
                />
              )}
            </Stack>
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack
              horizontal
              verticalAlign="end"
              horizontalAlign="space-between"
            >
              <Tabs />
            </Stack>
          </Stack>
        </Stack>
        <Stack tokens={{ padding: '0px 25px', childrenGap: 20 }}>
          <DocumentsSearchBar
            fileCabinetCommonData={fileCabinetCommonData}
            columns={gridColumns}
            onEnterPress={(value) => {
              setSearchValue(value);
              setGlobalSearchText(value);
            }}
            onFilterChange={setSearchFilters}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
            onRefresh={refetch}
          />
        </Stack>
      </Sticky>
      <InfiniteList<DocumentListItem>
        loading={loading}
        items={listData}
        hasNextPage={data?.fileCabinetDocumentSearch?.pageInfo.hasNextPage}
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onColumnHeaderClick={_onColumnClick}
        onLoadMore={() => handleSearch(true)}
        selectionMode={SelectionMode.multiple}
        onSelectionChanged={setSelectedList}
      />
      <DocumentViewModal
        paddingLeft={500}
        onDismiss={() => setDocViewState({ isOpen: false, _fileType: 'pdf' })}
        {...docViewState}
      />
    </>
  );
};
