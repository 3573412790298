import {
  NetworkStatus,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from '@apollo/client';
import {
  Checkbox,
  DirectionalHint,
  FontSizes,
  FontWeights,
  ICheckboxStyles,
  IColumn,
  IDetailsRowBaseProps,
  IDetailsRowProps,
  IRenderFunction,
  Icon,
  PrimaryButton,
  ProgressIndicator,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from '@fluentui/react';
import { setApprovalPanelReactive } from 'approvals/ApprovalDetails';
import { ApprovalSearchBar } from 'approvals/ApprovalSearchBar';
import { setApprovalLayoutType } from 'approvals/MyApprovals';
import {
  ApprovalHistoryActions,
  ApprovalHistoryActionsVariables,
} from 'approvals/__generated__/ApprovalHistoryActions';
import { setApprovalHistoryNodes } from 'approvals/ui';
import clsx from 'clsx';
import { AmountColumnTextView } from 'common/components/AmountView/AmountColumnTextView';
import { AmountTextView } from 'common/components/AmountView/AmountTextView';
import { FilterArrayType } from 'common/components/Filters';
import {
  HighLightActiveLink,
  HighlightTextView,
} from 'common/components/HighLight';
import { InfiniteList } from 'common/components/InfiniteList';
import {
  MultiSelectOption,
  MultiSelectTags,
} from 'common/components/MultiSelectTags';
import { ColumnData } from 'common/components/SearchBar';
import { TABLE_ROWS } from 'common/constants';
import {
  PayCycleBankingDocumentPackageStatus,
  PayCycleBankingDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PayCycleBankingDocumentPackageStatus';
import {
  PurchaseOrderDocumentPackageStatus,
  PurchaseOrderDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/PurchaseOrderDocumentPackageStatus';
import {
  TransactionDocumentPackageStatus,
  TransactionDocumentPackageStatusVariables,
} from 'common/graphql/DocumentPackageSubscription/__generated__/TransactionDocumentPackageStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalActionTypeInput,
  ApprovalHistoriesOrderBy,
  ApprovalHistoryFilter,
  DocumentPackageStatusType,
  SearchRequestInput,
} from 'common/types/globalTypes';
import { getColumn, getSortedColumns } from 'common/utils/columnUtilities';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import {
  dateConvertions,
  dateFormat,
  getGlobalDateFormat,
} from 'common/utils/dateFormats';
import { loader } from 'graphql.macro';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { StringParam, useQueryParams } from 'use-query-params';
import { globalMode, setGlobalSearchText } from 'utility/cache/ui';
import { ApprovalInputGroup } from '../ApprovalInputGroup';
import { ManageApprovers } from './ManageApprovers';
import {
  ApprovalHistorySearch,
  ApprovalHistorySearchVariables,
  ApprovalHistorySearch_approvalHistorySearch_nodes,
} from './__generated__/ApprovalHistorySearch';
import {
  ApprovalHistorySearchFilterTotals,
  ApprovalHistorySearchFilterTotalsVariables,
} from './__generated__/ApprovalHistorySearchFilterTotals';
import {
  ApprovalHistorySearchHints,
  ApprovalHistorySearchHintsVariables,
} from './__generated__/ApprovalHistorySearchHints';
import { useColumns } from './column.data';
import { useStyles } from './index.styles';
import {
  getFilterParams,
  toApprovalHistoryFilterVariables,
  toOrderByVariable,
} from './utils';
import { branchUserList_branchUserList_nodes } from './__generated__/branchUserList';
import { GetApprovalCommonData } from 'approvals/__generated__/GetApprovalCommonData';
const APPROVAL_HISTORY_ACTIONS = loader('../ApprovalHistoryActions.graphql');
const APPROVAL_HISTORY_SEARCH = loader('./ApprovalHistorySearch.graphql');
const APPROVAL_HISTORY_SEARCH_HINTS = loader(
  './ApprovalHistorySearchHints.graphql'
);
const APPROVAL_HISTORY_SEARCH_FILTER_TOTALS = loader(
  './ApprovalHistorySearchFilterTotals.graphql'
);
const DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/TransactionPackageStatus.graphql'
);
const PO_DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/PurchaseOrderDocumentPackageStatus.graphql'
);
const PAYMENT_DOCUMENT_PACKAGE_STATUS = loader(
  '../../common/graphql/DocumentPackageSubscription/PayCycleBankingDocumentPackageStatus.graphql'
);
const COMMON_DATA = loader('../GetApprovalCommonData.graphql');

export interface ApprovalHistoryItem
  extends ApprovalHistorySearch_approvalHistorySearch_nodes {
  key: string;
  name: string;
  number: string;
  description: string;
  date: string;
  amount: string;
  currencyData: string;
  role: string;
  status: string;
  departmentName: string;
  businessUnitName: string;
  periodYear: string;
  requesterDate: string;
  approvers: string;
  approvedDate: string | null;
  amendedByName: string;
  rejectors: string;
  rejectedDate: string;
  amendedDate: string;
  requesterName: string;
  additionalApprovalInformation: string;
  phaseName: string;
}

interface ApprovalsHistoryNavProps {
  processDone: boolean;
}

export const ApprovalsAllUsers: React.FC = () => {
  const renderRef = useRef(false);
  const history = useHistory();
  const globalState = useReactiveVar(globalMode);
  const globalSearchText = useReactiveVar(setGlobalSearchText);
  let approvalList: ApprovalHistoryItem[] = [];
  const [urlParameters, setUrlParameters] = useQueryParams({
    t: StringParam,
  });
  const { data: commonData } = useQuery<GetApprovalCommonData>(COMMON_DATA, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-only',
  });

  const { t: searchParam } = urlParameters;
  const [searchValue, setSearchValue] = useState<string | undefined | null>(
    searchParam! || null
  );
  const [renderControl, setRenderControl] = useState<boolean>(false);
  const hintFlagRef = useRef<boolean>(true);
  const { state } = useLocation<ApprovalsHistoryNavProps | undefined>();
  const [historyFilter, sethistoryFilter] = useState(false);
  const [selectedHint, setselectedHint] = useState<number | null>();
  const [queuedFilter, setqueuedFilter] = useState(false);
  const [searchFilters, setSearchFilters] = useState<FilterArrayType[]>();
  const [sortOrderParam, setSortOrderParam] = useState<SortOrder>();
  const [approvalActionList, setapprovalActionList] = useState<
    ApprovalActionTypeInput[]
  >([]);
  const [selectedRows, setSelectedRows] = useState<ApprovalHistoryItem[]>([]);

  const client = useApolloClient();
  const [actionPendingFilter, setActionPendingFilter] = useState(!globalState);
  const [isAllApprovalChecked, setIsAllApprovalChecked] = React.useState(false);
  const { addToast } = useToasts();
  const { columns } = useColumns();
  const [gridColumns, setGridColumns] = useState<ColumnData[]>(columns);
  const [isUserApprover, setIsUserApprover] = useState<boolean>(false);
  const [isUserRequestor, setIsUserRequestor] = useState<boolean>(false);
  const [approverUser, setApproverUser] =
    useState<branchUserList_branchUserList_nodes | null>(null);
  const [unSignedCheck, setUnSignedCheck] = useState(false);

  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const isApprovalPanelVisible = useReactiveVar(setApprovalPanelReactive);

  const [
    fetchApprovalHistory,
    {
      loading: approvalHistoryLoading,
      data: approvalHistoryData,
      variables,
      networkStatus,
      fetchMore,
      refetch,
    },
  ] = useLazyQuery<ApprovalHistorySearch, ApprovalHistorySearchVariables>(
    APPROVAL_HISTORY_SEARCH,
    {
      variables: {
        first: TABLE_ROWS,
        isHistorySearch: false,
        approverUserId: null,
        isApproverUserApprover: false,
        isApproverUserRequester: false,
        isQueuedSearch: false,
        isLinkSearch: false,
        searchRequest: { searchText: [''], searchHintId: null },
        orderBy: [
          ApprovalHistoriesOrderBy.DESCRIPTION_1_ASC,
          ApprovalHistoriesOrderBy.SORT_ORDER_GROUP_DESC,
          ApprovalHistoriesOrderBy.PRIMARY_KEY_ASC,
        ],
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );

  const [fetchTotals, { data: totalsData, variables: totalsVariable }] =
    useLazyQuery<
      ApprovalHistorySearchFilterTotals,
      ApprovalHistorySearchFilterTotalsVariables
    >(APPROVAL_HISTORY_SEARCH_FILTER_TOTALS, {
      variables: {
        isQueuedSearch: false,
        isHistorySearch: false,
        approverUserId: null,
        isApproverUserApprover: false,
        isApproverUserRequester: false,
        searchRequest: {
          searchText: [''],
          searchHintId: null,
          isMatchAny: true,
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  useEffect(() => {
    if (state?.processDone) {
      setapprovalActionList([]);
      fetchTotals({ variables: totalsVariable });
    }
  }, [state]);

  useEffect(() => {
    const filteredNodes =
      approvalHistoryData?.approvalHistorySearch?.nodes?.filter(
        (item) => item?.iconType?.isApprovalActionAvailable
      );
    setApprovalHistoryNodes(filteredNodes!);
  }, [approvalHistoryData]);

  const [processApproval, { loading: processApprovalLoading }] = useMutation<
    ApprovalHistoryActions,
    ApprovalHistoryActionsVariables
  >(APPROVAL_HISTORY_ACTIONS, { errorPolicy: 'all' });

  const [fetchApprovalHistoryHints, { data: approvalHistoryHintsData }] =
    useLazyQuery<
      ApprovalHistorySearchHints,
      ApprovalHistorySearchHintsVariables
    >(APPROVAL_HISTORY_SEARCH_HINTS, {
      variables: {
        searchRequest: { searchText: [''], searchHintId: null },
        isHistorySearch: false,
        approverUserId: null,
        isApproverUserApprover: false,
        isApproverUserRequester: false,
        isQueuedSearch: false,
        isLinkSearch: false,
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    });

  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;

  // TRANSFORMING THE DATA FOR UI BINDING WITH LIST
  const transformedData = refetching
    ? undefined
    : approvalHistoryData?.approvalHistorySearch?.nodes.map(
        (appr) =>
          ({
            ...appr,
            ...appr.approvalHistoryApprover,
            key: appr.id,
            name: appr.description1,
            number: appr.description3,
            description: appr.description2,
            date: appr.date1 ? appr.date1 : '',
            amount: appr.amount1?.toString(),
            currencyData: appr.currency?.isoCode,
            role: appr.approvalPersona,
            status: appr.iconType?.iconType,
            departmentName: appr.department?.name,
            businessUnitName: appr.businessUnit?.name,
            periodYear: appr.companyCorporatePeriod?._periodYear,
            additionalApprovalInformation: appr.additionalApprovalInformation,
            phaseName: appr.approvalHistoryPhaseType?.phaseName,
          } as ApprovalHistoryItem)
      );
  if (transformedData) approvalList = transformedData;
  const _renderItemColumn = (
    item: ApprovalHistoryItem | undefined,
    _index: number | undefined,
    column: IColumn | undefined
  ) => {
    if (item) {
      const fieldContent = item?.[
        column?.fieldName as keyof ApprovalHistoryItem
      ] as string;
      let approverData;
      if (item.approvalHistoryApprover?.approvers)
        approverData = item.approvalHistoryApprover?.approvers
          .split(',')
          .join(', ');
      let rejectorsList: string = '';
      if (item?.approvalHistoryApprover?.rejectors)
        rejectorsList = item?.approvalHistoryApprover?.rejectors
          .split(',')
          .join(', ');
      let selectedApproval = approvalActionList.filter((id) => {
        return id.entityApprovalBranchId === item.entityApprovalBranchId;
      });
      const approvalData = selectedApproval[0];
      const { _isUrgentApproval } = { ...item };
      const Content: React.FC = () => {
        return (
          <Stack className={styles.container}>
            <Stack className={styles.urgentHeader}>
              <Text className={styles.title}>Urgent</Text>
            </Stack>
            <Stack tokens={{ padding: 20 }}>
              <Text>{item?.approvalTopic}</Text>
            </Stack>
          </Stack>
        );
      };
      switch (column?.key) {
        case 'urgencyLevel':
          return (
            <TooltipHost className={styles.containerMain} content={<Content />}>
              <Stack
                tokens={{ padding: '0px 0px 0px 10px' }}
                verticalAlign="center"
              >
                {_isUrgentApproval && (
                  <Icon
                    iconName="FabricFolderFill"
                    className={commonStyles.urgentBox}
                  />
                )}
              </Stack>
            </TooltipHost>
          );
        case 'name':
          return (
            <Stack
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
              horizontal
              className={styles.onrenderColumnStack}
            >
              {isApprovalPanelVisible && (
                <ApprovalInputGroup
                  selectedApproval={approvalData}
                  approvalData={item}
                  onChange={onActionChange}
                  hideActionButtons
                />
              )}

              <HighLightActiveLink
                key={item?.key}
                highlightText={globalSearchText}
                text={item?.name}
                to={`/approval_history/approval/${item?.key}`}
                preventDefault
                onLinkClick={() => {
                  setApprovalLayoutType(item?.layoutType);
                  history.push(`/approval_history/approval/${item?.key}`);
                }}
              />
            </Stack>
          );
        case 'status':
          return (
            <Stack
              verticalAlign="center"
              tokens={{ padding: '0px 0px 0px 20px' }}
              className={styles.onrenderColumnStack}
            >
              <ApprovalInputGroup
                selectedApproval={approvalData}
                approvalData={item}
                onChange={onActionChange}
                refetchAllApproval={true}
              />
            </Stack>
          );
        case 'date':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                className={styles.contentColumnAlignRight}
                highlightText={globalSearchText}
                text={
                  fieldContent === null || !fieldContent
                    ? ''
                    : dateFormat(dateConvertions(fieldContent))
                }
              />
            </Stack>
          );
        case 'amount':
          return (
            <AmountColumnTextView
              value={fieldContent}
              searchText={globalSearchText}
            />
          );
        case 'requesterDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={
                  item?.approvalHistoryApprover?.requesterDate! &&
                  getGlobalDateFormat(
                    item.approvalHistoryApprover?.requesterDate!
                  )
                }
              />
            </Stack>
          );
        case 'approvedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={
                  item?.approvalHistoryApprover?.approvedDate! &&
                  getGlobalDateFormat(
                    item.approvalHistoryApprover?.approvedDate!
                  )
                }
              />
            </Stack>
          );
        case 'rejectedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={
                  item?.approvalHistoryApprover?.rejectedDate! &&
                  getGlobalDateFormat(
                    item.approvalHistoryApprover?.rejectedDate!
                  )
                }
              />
            </Stack>
          );
        case 'amendedDate':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={
                  item?.approvalHistoryApprover?.amendedDate! &&
                  getGlobalDateFormat(
                    item.approvalHistoryApprover?.amendedDate!
                  )
                }
              />
            </Stack>
          );
        case 'approvers':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={approverData ? approverData : ''}
              />
            </Stack>
          );
        case 'rejectors':
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              <HighlightTextView
                highlightText={globalSearchText}
                text={rejectorsList}
              />
            </Stack>
          );
        default:
          return (
            <Stack
              verticalAlign="center"
              className={styles.onrenderColumnStack}
            >
              {fieldContent && (
                <HighlightTextView
                  highlightText={globalSearchText}
                  text={fieldContent}
                />
              )}
            </Stack>
          );
      }
    }
  };

  const _onRenderRow: IRenderFunction<IDetailsRowProps> = (
    props,
    defaultRender
  ) => {
    if (!props) {
      return null;
    }

    const item: ApprovalHistoryItem = { ...props.item };
    const { _isUrgentApproval } = { ...item };

    const newProps: IDetailsRowProps | undefined = props
      ? {
          ...props,
          className: clsx(
            styles.rowBaseStyle,
            _isUrgentApproval ? commonStyles.urgentRow : styles.rowBaseStyle
          ),
        }
      : undefined;
    const tootTipMessage = 'View to approve.';
    const tooltipVisible =
      !item.isRequester &&
      !(
        item.iconType?.isApprovalActionAvailable &&
        item._isUserEnMasseApprovalAllowed &&
        !item._isValidating
      );
    const toolTipContent = tooltipVisible ? tootTipMessage : '';

    return (
      <>
        <TooltipHost
          content={toolTipContent}
          directionalHint={DirectionalHint.topLeftEdge}
          tooltipProps={{
            styles: { root: { marginLeft: 100 } },
          }}
        >
          {defaultRender?.(newProps)}
        </TooltipHost>
      </>
    );
  };

  const handleSearch = (showMore: boolean, newHints: boolean) =>
    handleSeachGlobal(showMore, newHints, globalState);

  // Handle Search query with globalSearch param
  const handleSeachGlobal = (
    showMore: boolean,
    newHints: boolean,
    globalValue: boolean
  ) => {
    if (!showMore) {
      setapprovalActionList([]);
    }
    setIsAllApprovalChecked(false);
    const searchRequest: SearchRequestInput = {
      searchText: [searchValue ? searchValue : ''],
      searchHintId: selectedHint,
    };
    const searchHintsRequest: SearchRequestInput = {
      searchText: [searchValue ? searchValue : ''],
      searchHintId: null,
    };

    const variables: ApprovalHistorySearchVariables = {
      first: TABLE_ROWS,
      isHistorySearch: historyFilter,
      approverUserId: approverUser?.id,
      isApproverUserApprover: isUserApprover,
      isApproverUserRequester: isUserRequestor,
      isOnlyUnsignedApprovals: unSignedCheck,
      isQueuedSearch: queuedFilter,
      searchRequest,
      after: showMore
        ? approvalHistoryData?.approvalHistorySearch?.pageInfo.endCursor
        : undefined,
      filter: searchFilters?.length
        ? ({
            and: [...toApprovalHistoryFilterVariables(searchFilters)],
          } as ApprovalHistoryFilter)
        : undefined,
      orderBy: toOrderByVariable(sortOrderParam!, historyFilter),
      isLinkSearch: globalValue,
    };
    const filterParams = getFilterParams(searchFilters || []);

    const variableHints: ApprovalHistorySearchHintsVariables = {
      searchRequest: searchHintsRequest,
      isHistorySearch: historyFilter,
      approverUserId: approverUser?.id,
      isApproverUserApprover: isUserApprover,
      isApproverUserRequester: isUserRequestor,
      isOnlyUnsignedApprovals: unSignedCheck,
      isQueuedSearch: queuedFilter,
      isLinkSearch: globalValue,
    };
    const totalFilterVariables: ApprovalHistorySearchFilterTotalsVariables = {
      isLinkSearch: globalValue,
      isQueuedSearch: queuedFilter,
      searchRequest,
      isHistorySearch: historyFilter,
      approverUserId: approverUser?.id,
      isApproverUserApprover: isUserApprover,
      isApproverUserRequester: isUserRequestor,
      isOnlyUnsignedApprovals: unSignedCheck,
      ...filterParams,
    };

    const searchQueryParam =
      searchValue && searchValue.length > 0 ? searchValue : undefined;

    setUrlParameters({ t: searchQueryParam }, 'replace');

    if (showMore) fetchMore?.({ variables });
    else {
      fetchApprovalHistory({ variables });
      fetchTotals({ variables: totalFilterVariables });
    }
    if (newHints) fetchApprovalHistoryHints({ variables: variableHints });
  };

  const updateAllApprovalSelections = (
    approvalSelectedData: ApprovalActionTypeInput[] | undefined
  ) => {
    const intialApprovalData = approvalList.filter((item) => {
      return item.iconType?.isApprovalActionAvailable;
    });
    const updatedApprovalData = approvalSelectedData?.filter((item) => {
      return item.isApproval;
    });
    if (intialApprovalData.length === updatedApprovalData?.length)
      setIsAllApprovalChecked(true);
    else setIsAllApprovalChecked(false);
  };

  const onActionChange = (
    inputData: ApprovalActionTypeInput | undefined,
    remove: boolean
  ) => {
    if (inputData) {
      let newApprovalActionList = [...approvalActionList];
      const position = newApprovalActionList.findIndex(
        (item) =>
          item.entityApprovalBranchId === inputData.entityApprovalBranchId
      );
      if (position === -1) {
        newApprovalActionList.push(inputData);
      } else {
        if (remove) newApprovalActionList.splice(position, 1);
        else newApprovalActionList[position] = inputData;
      }
      setapprovalActionList(newApprovalActionList);
      //To update checkbox value
      updateAllApprovalSelections(newApprovalActionList);
    }
  };

  const onProcessApproval = async () => {
    const { errors, data: responseAfterApproval } = await processApproval({
      variables: {
        input: {
          approveDeclineActions: approvalActionList,
        },
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<
          ApprovalHistorySearch,
          ApprovalHistorySearchVariables
        >({
          query: APPROVAL_HISTORY_SEARCH,
          variables: variables,
        });
        if (
          data?.approvalHistoryActions?.approvalActionResults &&
          data?.approvalHistoryActions?.approvalActionResults?.length > 0
        ) {
          const mutationResponseArray =
            data.approvalHistoryActions.approvalActionResults.map(
              (ele) => ele?.approvalHistory
            );
          const mergedArray = cacheData?.approvalHistorySearch?.nodes.map(
            (obj) =>
              mutationResponseArray.find((item) => item?.id === obj.id) || obj
          );
          const updatedData = {
            ...cacheData!,
            approvalHistorySearch: {
              ...cacheData?.approvalHistorySearch!,
              nodes: mergedArray,
              pageInfo: {
                ...cacheData?.approvalHistorySearch?.pageInfo!,
              },
            },
          };
          cache.writeQuery({
            query: APPROVAL_HISTORY_SEARCH,
            data: updatedData,
            variables: variables,
          });
        }
      },
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      fetchTotals({ variables: totalsVariable });
      setapprovalActionList([]);
      addToast('Your approvals have been processed', { appearance: 'success' });
      responseAfterApproval?.approvalHistoryActions?.approvalActionResults?.forEach(
        (item) => {
          const { isApproved, approvalType, entityId } = {
            ...item?.approvalHistory,
          };

          if (isApproved && entityId) {
            if (approvalType?._isSigningStandalone) {
              const observer = client.subscribe<
                TransactionDocumentPackageStatus,
                TransactionDocumentPackageStatusVariables
              >({
                query: DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.transactionDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...item?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else if (approvalType?._isPurchaseOrderSystem) {
              const observer = client.subscribe<
                PurchaseOrderDocumentPackageStatus,
                PurchaseOrderDocumentPackageStatusVariables
              >({
                query: PO_DOCUMENT_PACKAGE_STATUS,
                variables: {
                  id: entityId,
                },
              });

              const subscription = observer.subscribe(({ data, errors }) => {
                if (errors)
                  addToast(
                    'Errors received while Subscribing to document package',
                    { appearance: 'error' }
                  );
                else {
                  const { document, status } = {
                    ...data?.purchaseOrderDocumentPackageStatus,
                  };
                  if (
                    status === DocumentPackageStatusType.REGENERATION_FAILURE
                  ) {
                    addToast(
                      'Report generation failed. Document package was not created',
                      { appearance: 'error' }
                    );
                  }
                  if (status === DocumentPackageStatusType.FAILURE) {
                    addToast('Error while creating Document package ', {
                      appearance: 'error',
                    });
                  }
                  if (document) {
                    addToast('Document package created', {
                      appearance: 'success',
                    });
                    client.cache.modify({
                      id: client.cache.identify({
                        ...item?.approvalHistory,
                      }),
                      fields: {
                        _documentPackageId: () => {
                          return document.id;
                        },
                      },
                    });
                  }
                }
                subscription.unsubscribe();
              });
            } else if (approvalType?._isPaymentTrackingStandalone) {
              item?.approvalHistory?.approvalHistoryItemsByDisplayEntityApprovalId.nodes.forEach(
                (item) => {
                  const observer = client.subscribe<
                    PayCycleBankingDocumentPackageStatus,
                    PayCycleBankingDocumentPackageStatusVariables
                  >({
                    query: PAYMENT_DOCUMENT_PACKAGE_STATUS,
                    variables: {
                      id: item.entityItemId!,
                    },
                  });

                  const subscription = observer.subscribe(
                    ({ data, errors }) => {
                      if (errors)
                        addToast(
                          'Errors received while Subscribing to document package',
                          { appearance: 'error' }
                        );
                      else {
                        const { status } = {
                          ...data?.payCycleBankingDocumentPackageStatus,
                        };
                        if (
                          status ===
                          DocumentPackageStatusType.REGENERATION_FAILURE
                        ) {
                          addToast(
                            'Report generation failed. Document package was not created',
                            { appearance: 'error' }
                          );
                        }
                        if (status === DocumentPackageStatusType.FAILURE) {
                          addToast('Error while creating Document package ', {
                            appearance: 'error',
                          });
                        }
                      }
                      subscription.unsubscribe();
                    }
                  );
                }
              );
            } else {
            }
          }
        }
      );
    }
  };

  const _onColumnClick = (clickedColumn: ColumnData) => {
    const { newColumns, desc } = getSortedColumns(clickedColumn, gridColumns);
    setGridColumns(newColumns);
    setSortOrderParam({
      column: clickedColumn.key,
      direction: desc ? OrderDirection.DESC : OrderDirection.ASC,
    });
  };

  const getNewColumns = () => {
    if (sortOrderParam?.column !== '' && sortOrderParam) {
      const columnData = getColumn(sortOrderParam?.column!, gridColumns);
      const sortedColumns = getSortedColumns(columnData, gridColumns);
      return sortedColumns;
    } else return undefined;
  };

  const getNewColumnsMemo = useCallback(getNewColumns, []);

  useEffect(() => {
    const sortedColumns = getNewColumnsMemo();
    if (sortedColumns && renderRef.current)
      setGridColumns(sortedColumns.newColumns);
  }, [getNewColumnsMemo]);

  const handleSeachGlobalWithNewHintsMemo = useCallback(handleSearch, [
    searchValue,
    queuedFilter,
    historyFilter,
    searchFilters,
    actionPendingFilter,
    isUserApprover,
    isUserRequestor,
    unSignedCheck,
    approverUser,
  ]);

  const handleSeachGlobalMemo = useCallback(handleSearch, [
    sortOrderParam,
    selectedHint,
  ]);

  useEffect(() => {
    if (renderRef.current === true) {
      handleSeachGlobalMemo(false, false);
    }
  }, [selectedHint, sortOrderParam, handleSeachGlobalMemo]);

  useEffect(() => {
    handleSeachGlobalWithNewHintsMemo(false, true);
    renderRef.current = true;
  }, [
    searchValue,
    queuedFilter,
    historyFilter,
    searchFilters,
    isUserApprover,
    isUserRequestor,
    unSignedCheck,
    approverUser,
    handleSeachGlobalWithNewHintsMemo,
  ]);

  const searchHintsOptions: MultiSelectOption[] =
    approvalHistoryHintsData?.approvalHistorySearchHints?.nodes.map(
      (hint) =>
        ({
          key: hint.id?.toString(),
          text: `${hint.caption} (${hint.resultsCount})`,
        } as MultiSelectOption)
    ) || [];

  const _renderDetailsFooterItemColumn: IDetailsRowBaseProps['onRenderItemColumn'] =
    (_item, _index, column) => {
      if (column?.key === 'amount') {
        return (
          <AmountTextView
            variant="medium"
            className={clsx(styles.amountStack, styles.contentColumnAlignRight)}
            value={totalsData?.approvalHistorySearchFilterTotals?.totalAmount1!}
          />
        );
      }

      return '';
    };

  const approveAll = (isChecked: boolean) => {
    if (isChecked) {
      setIsAllApprovalChecked(!isAllApprovalChecked);
      let approvalData: ApprovalActionTypeInput[] = [];
      approvalList.filter((item) => {
        const isApprovable =
          item.iconType?.isApprovalActionAvailable &&
          item._isUserEnMasseApprovalAllowed;
        if (isApprovable) {
          approvalData.push({
            entityApprovalBranchId: item?.entityApprovalBranchId!,
            entityApprovalBranchRowTimestamp:
              item?.entityApprovalBranchRowTimestamp!,
            isDecline: false,
            isApproval: true,
            comments: null,
            isApproveBypassLowerLevels: null,
          });
        }
        return approvalData;
      });
      setapprovalActionList(approvalData);
    } else {
      setIsAllApprovalChecked(!isAllApprovalChecked);
      setapprovalActionList([]);
    }
  };

  const showFooter =
    !refetching &&
    parseFloat(
      totalsData?.approvalHistorySearchFilterTotals?.totalAmount1!
      // approvalHistoryData?.approvalHistorySearch?.aggregates?.sum?.amount1!
    ) > 0;

  const massApprovalRows = approvalList.filter((item) => {
    return (
      item.iconType?.isApprovalActionAvailable &&
      item._isUserEnMasseApprovalAllowed &&
      !item._isValidating
    );
  });

  const requesterRows = approvalList.filter((item) => item.isRequester);

  const isShowApproval = !!massApprovalRows.length
    ? approvalList.length === massApprovalRows.length + requesterRows.length
    : false;

  useEffect(() => {
    if (approvalList.length && hintFlagRef.current) {
      setRenderControl(true);
      hintFlagRef.current = false;
    }
  }, [approvalList]);

  return (
    <>
      <Stack
        tokens={{ childrenGap: 20 }}
        className={commonStyles.listHeaderContainer}
      >
        <Stack
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          className={commonStyles.listTitleContainer}
        >
          <Text variant="xLarge">All Approvals</Text>
          <Stack verticalAlign="center" tokens={{ childrenGap: 20 }} horizontal>
            {!!commonData?.verifyApprovalMaintenancePermission && (
              <ManageApprovers
                approverUser={approverUser}
                selectedRows={selectedRows}
                onSuccess={() => {
                  refetch?.();
                }}
              />
            )}
            <PrimaryButton
              disabled={
                approvalActionList.length === 0 ||
                !approvalActionList ||
                processApprovalLoading
              }
              text="Process approval"
              onClick={onProcessApproval}
            />
          </Stack>
        </Stack>
        <Stack>
          <ApprovalSearchBar
            isAllApproval
            setIsUserApprover={setIsUserApprover}
            isUserApprover={isUserApprover}
            isUserRequestor={isUserRequestor}
            setIsUserRequestor={setIsUserRequestor}
            setApproverUser={setApproverUser}
            approverUser={approverUser}
            urlParams={{
              searchParam: searchParam!,
            }}
            columns={gridColumns}
            hintsAvailable={false}
            onQueuedToogle={(value) => {
              if (renderRef.current) globalMode(false);
              setqueuedFilter(value);
            }}
            onFilterChange={setSearchFilters}
            onHistoryToogle={(value) => {
              if (renderRef.current) globalMode(false);
              sethistoryFilter(value);
            }}
            onActionPendingToggle={(value) => {
              if (renderRef.current) globalMode(false);
              setActionPendingFilter(value);
            }}
            actionPendingFilter={actionPendingFilter}
            onRefresh={() => handleSearch(false, false)}
            onEnterPress={(value) => {
              setSearchValue(value);
              setGlobalSearchText(value);
            }}
            onToggleVisibility={(columns) => {
              setGridColumns(columns);
            }}
            onGlobalToggle={(value) => {
              handleSeachGlobal(false, true, value);
            }}
            onUnsignedCheck={setUnSignedCheck}
          />
          {processApprovalLoading && <ProgressIndicator />}

          {renderControl && (
            <Stack
              horizontal
              verticalAlign={'center'}
              horizontalAlign={'space-between'}
            >
              {searchHintsOptions.length > 0 && (
                <MultiSelectTags
                  multiSelect={false}
                  options={searchHintsOptions}
                  onSelectionChange={(selctedTags) => {
                    const hints = selctedTags.map((item) => parseInt(item.key));
                    setselectedHint(hints[0]);
                  }}
                />
              )}
              {isShowApproval && (
                <Checkbox
                  boxSide="end"
                  styles={checkBoxStyle}
                  checked={isAllApprovalChecked}
                  label="Approve All"
                  onChange={(_, value) => {
                    approveAll(value!);
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>
      </Stack>

      <InfiniteList
        items={approvalList}
        loading={approvalHistoryLoading}
        hasNextPage={
          approvalHistoryData?.approvalHistorySearch?.pageInfo.hasNextPage
        }
        totalRowsCount={
          totalsData?.approvalHistorySearchFilterTotals?.totalCount1
        }
        showFooter={showFooter}
        selectionMode={
          unSignedCheck && approverUser !== null
            ? SelectionMode.multiple
            : SelectionMode.none
        }
        columns={gridColumns.filter((_column) => _column.isVisible)}
        onRenderItemColumn={_renderItemColumn}
        onRenderFooterItemColumn={_renderDetailsFooterItemColumn}
        onColumnHeaderClick={(_, column) => {
          if (column) _onColumnClick(column);
        }}
        onSelectionChanged={setSelectedRows}
        onLoadMore={() => handleSearch(true, false)}
        onRenderRow={_onRenderRow}
      />
    </>
  );
};

let checkBoxStyle: ICheckboxStyles = {
  label: {
    fontWeight: FontWeights.bold,
    fontSize: FontSizes.size10,
    color: '#a9a9a9',
  },
};
