import { IButtonStyles, IconButton, Stack, Text } from '@fluentui/react';
import React from 'react';
import getButtonStylesCustom, { getToggleStyles } from '../Buttons/ToggleButton/utils';
import { useStyles } from './index.styles';

interface ChipProps {
  text: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: string;
  iconVisible?: boolean;
  buttonStylesCustom?: boolean;
}
export const Chip: React.FC<ChipProps> = ({
  text,
  onClick,
  disabled = false,
  color,
  iconVisible = true,
  buttonStylesCustom,
}) => {
  const styles = useStyles();
  const buttonColor = disabled ? 'DISABLED' : color ? color : '';
  const buttonStyles: IButtonStyles = buttonStylesCustom
    ? getButtonStylesCustom(buttonColor)
    : getToggleStyles(buttonColor);

  return (
    <Stack horizontal>
      {iconVisible && (
        <IconButton
          disabled={disabled}
          onClick={onClick}
          iconProps={{ iconName: 'Cancel' }}
          styles={buttonStyles}
        />
      )}
      <Stack
        tokens={{ padding: '5px 15px 5px 2px' }}
        styles={buttonStyles}
        style={{ height: 32 }}
      >
        <Text className={styles.chipText}>{text}</Text>
      </Stack>
    </Stack>
  );
};
