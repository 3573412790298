import { Label, Stack, TextField, TooltipHost } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DangerButton } from 'common/components/DangerButton';
import React, { useState } from 'react';
import { ManageApproversProps } from '..';
import { useStyles } from './index.styles';
import { useMutation } from '@apollo/client';
import { loader } from 'graphql.macro';
import {
  ReplaceApproverApprovals,
  ReplaceApproverApprovalsVariables,
} from '../__generated__/ReplaceApproverApprovals';
import { useToasts } from 'react-toast-notifications';
import { ExceptionModal } from '../Exception';
const MUTATION = loader('./../ReplaceApproverApprovals.graphql');

export const BypassApprover: React.FC<
  Pick<ManageApproversProps, 'selectedRows' | 'approverUser' | 'onSuccess'>
> = (props) => {
  const { addToast } = useToasts();
  const styles = useStyles();
  const [showErrorResponse, setShowErrorResponse] = useState(false);
  const [reason, setReason] = useState<string | undefined>(undefined);
  // MUTATION
  const [bypassApprover, { data: response }] = useMutation<
    ReplaceApproverApprovals,
    ReplaceApproverApprovalsVariables
  >(MUTATION);

  const [visible, setVisible] = useState(false);

  const onDismiss = () => {
    setVisible(false);
  };

  const _onConfirm = async () => {
    setVisible(false);
    const entityApprovalIds = props.selectedRows.map((item) => item.id);

    const { errors, data } = await bypassApprover({
      variables: {
        input: {
          originalUserId: props.approverUser?.id!,
          entityApprovalIds: entityApprovalIds,
          isBypassOnly: true,
          reason: reason || '',
        },
      },
      awaitRefetchQueries: true,
    });
    if (!!data?.replaceApproverApprovals?.processRequests?.length) {
      setShowErrorResponse(true);
    }
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      if (
        data?.replaceApproverApprovals?.processRequests?.length ===
        entityApprovalIds.length
      ) {
        addToast('Approvers could not be bypassed', {
          appearance: 'error',
        });
      } else {
        addToast(`Approvers bypassed successfully`, {
          appearance: 'success',
        });
        if (!data?.replaceApproverApprovals?.processRequests?.length) {
          props.onSuccess();
        }
      }
    }
  };

  return (
    <Stack>
      <TooltipHost content={'Bypass approvers'}>
        <DangerButton
          iconProps={{ iconName: 'UserRemove' }}
          text="Bypass"
          primary
          onClick={() => {
            setVisible(true);
          }}
        />
      </TooltipHost>
      <ConfirmDialog
        isConfirmPrimaryButton
        successBtnDisable={!!!reason?.length}
        hidden={!visible}
        title={`Bypass approver - ${props.approverUser?.name}`}
        onDismiss={onDismiss}
        minWidth={450}
        onConfirm={_onConfirm}
      >
        <Stack tokens={{ childrenGap: '10px', padding: '20px 0px 20px 0px' }}>
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Label className={styles.width100}>Reason for bypass</Label>
            <TextField
              className={styles.textInputStyle}
              multiline
              rows={3}
              value={reason}
              placeholder="Please write your reason here"
              resizable={false}
              onChange={(_event, value) => setReason(value)}
            />
          </Stack>
        </Stack>
      </ConfirmDialog>

      <ExceptionModal
        showErrorResponse={showErrorResponse}
        response={response}
        onDismiss={() => {
          props.onSuccess();
          setShowErrorResponse(false);
        }}
      />
    </Stack>
  );
};
