import React from 'react';
import { ReactComponent as DashboardIcon } from '../assets/svgs/nav/Dashboard.svg';
import { ReactComponent as AccountsPayableIcon } from '../assets/svgs/nav/AccountsPayable.svg';
import { ReactComponent as PurchaseOrderIcon } from '../assets/svgs/nav/PurchaseOrder.svg';
import { ReactComponent as DocumentsIcon } from '../assets/svgs/nav/Documents.svg';
import { ReactComponent as ApprovalsIcon } from '../assets/svgs/nav/Approvals.svg';
import { ReactComponent as PostingTracker } from '../assets/svgs/nav/PostingTracker.svg';
import { ReactComponent as PaymentTracker } from '../assets/svgs/nav/PaymentTracker.svg';
import { ReactComponent as TravelAuthIcon } from '../assets/svgs/nav/TravelAuthorization.svg';
import { Optional } from 'common/types/utility';
import { AppRouter } from 'common/types/globalTypes';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/client';
import { CommonDataOnAppLoading } from 'common/graphql/__generated__/CommonDataOnAppLoading';
const COMMON_APP_DATA = loader(
  '../common/graphql/CommonDataOnAppLoading.graphql'
);

type SVGIcon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string }
>;
type FontIconName = string;

export type SVGIconOrFontIconName = SVGIcon | FontIconName;

export interface INavItem {
  name: string;
  Icon: SVGIconOrFontIconName;
  path: string;
  exact?: boolean;
  restricted?: 'administrator' | 'supervisor';
  routeName?: AppRouter | undefined;
  count?: number | undefined | null;
}

export type INavGroup = Optional<INavItem, 'path'> & {
  items?: INavItem[];
};

export const getUserPrivilegedNav = (
  navGroups: INavGroup[],
  privileges: Set<string>
) => {
  const newNavGroups: INavGroup[] = navGroups
    .map((navItem) => {
      const items = navItem.items?.filter(
        (item) =>
          privileges.has(item.routeName!) || item.routeName === undefined
      );
      const newNavItem: INavGroup = { ...navItem, items };
      return newNavItem;
    })
    .filter((item) => item?.items?.length! > 0 || item.items === undefined)
    .filter(
      (item) =>
        (item.routeName && privileges.has(item.routeName)) ||
        item.routeName === undefined
    );

  return newNavGroups;
};

export const useAppNav = () => {
  const { data } = useQuery<CommonDataOnAppLoading>(COMMON_APP_DATA);

  const getNavGroups = (privileges: Set<string>) => {
    const navGroups: INavGroup[] = [
      {
        name: 'Dashboard',
        path: '/',
        exact: true,
        Icon: DashboardIcon,
      },
      {
        name: 'Documents',
        Icon: DocumentsIcon,
        items: [
          {
            name: 'Document Assignment',
            path: '/doc/documentAssignment',
            Icon: 'BulkUpload',
            routeName: AppRouter.DOCUMENT_POOL_FILE_LOADER,
          },
          {
            name: 'My Documents',
            path: '/doc/personal',
            Icon: 'TextDocumentShared',
            routeName: AppRouter.PRIVATE_POOL,
          },
          {
            name: 'Folder Setup',
            path: '/doc/groups',
            Icon: 'FolderOpen',
            routeName: AppRouter.DOCUMENT_POOL,
          },
          {
            name: 'File Cabinet',
            path: '/doc/fileCabinet',
            Icon: 'DocumentSet',
            routeName: AppRouter.FILE_CABINET,
          },
        ],
      },
      {
        name: 'Transaction Signing',
        Icon: AccountsPayableIcon,
        routeName: AppRouter.TRANSACTION_SIGNING,
        path: '/signing',
      },
      {
        name: 'Approvals',
        Icon: ApprovalsIcon,
        count: data?.userPendingApprovalCount,
        items: [
          {
            name: 'My Approvals',
            path: '/approvals',
            Icon: 'InboxCheck',
            routeName: AppRouter.APPROVALS,
          },
          {
            name: 'All Approvals',
            path: '/approval_history',
            Icon: 'CheckList',
            restricted: 'supervisor',
            routeName: AppRouter.APPROVAL_HISTORY,
          },
        ],
      },
      {
        name: 'Posting Tracker',
        Icon: PostingTracker,
        items: [
          {
            name: 'Batch/Edit',
            path: '/postingTracker/batches',
            Icon: 'InsertSignatureLine',
            routeName: AppRouter.BATCH_EDIT,
          },
          {
            name: 'Transaction History',
            path: '/postingTracker/history',
            Icon: 'InsertSignatureLine',
            routeName: AppRouter.POSTED_TRANSACTIONS,
          },
        ],
      },

      {
        name: 'Payment Tracker',
        Icon: PaymentTracker,
        items: [
          {
            name: 'Pay Cycles',
            path: '/pay/payment_cycle',
            Icon: 'Transition',
            routeName: AppRouter.PAY_CYCLE,
          },
          {
            name: 'Payments',
            path: '/pay/payment_tracking',
            Icon: 'Money',
            routeName: AppRouter.PAYMENT_TRACKING,
          },
        ],
      },
      {
        name: 'Purchase Orders',
        Icon: PurchaseOrderIcon,
        routeName: AppRouter.PURCHASE_ORDER,
        path: '/purchase-orders',
      },

      {
        name: 'Travel Authorization',
        Icon: TravelAuthIcon,
        items: [
          {
            name: 'Travel Plan',
            Icon: 'Airplane',
            routeName: AppRouter.TRAVEL_AUTHORIZATION,
            path: '/ta/travel-plan',
          },
          {
            name: 'Trip Breakdown',
            Icon: 'AirTickets',
            routeName: AppRouter.TRAVEL_AUTHORIZATION,
            path: '/ta/trip-breakdown',
          },
        ],
      },
    ];
    const navGroupsPrivileged: INavGroup[] = getUserPrivilegedNav(
      navGroups,
      privileges
    );

    return navGroupsPrivileged;
  };

  return { getNavGroups };
};

export const bottomNavItems: INavItem[] = [
  {
    name: 'Project settings',
    Icon: 'Settings',
    path: '/project-settings',
    restricted: 'supervisor',
  },
  {
    name: 'Account management',
    Icon: 'AccountManagement',
    path: '/account-management',
    restricted: 'administrator',
  },
];
