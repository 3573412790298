import { ColumnActionsMode, IDetailsColumnStyles } from '@fluentui/react';
import { ColumnData } from 'common/components/SearchBar';
const centerAlignHeaderStyle: Partial<IDetailsColumnStyles> = {
  cellTitle: {
    justifyContent: 'center',
  },
};
const commonColumnProps: Partial<ColumnData> = {
  isResizable: true,
  isSorted: false,
  isPadded: false,
  isCollapsible: false,
  toggleVisibility: true,
  isVisible: true,
};

export const columns: ColumnData[] = [
  {
    key: '_urgencyLevel',
    name: '',
    fieldName: '_urgencyLevel',
    showColumnTitle: 'Urgent',
    minWidth: 10,
    maxWidth: 10,
    columnActionsMode: ColumnActionsMode.disabled,
    ...commonColumnProps,
  },
  {
    key: 'name',
    name: 'Name',
    fieldName: 'name',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
  },
  {
    key: 'description',
    name: 'Description',
    fieldName: 'description',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'emailAccount',
    name: 'Email',
    fieldName: 'emailAccount',
    minWidth: 150,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'roleName',
    name: 'Role',
    fieldName: 'roleName',
    minWidth: 90,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'occupation',
    name: 'Occupation',
    fieldName: 'occupation',
    minWidth: 180,
    maxWidth: 180,
    ...commonColumnProps,
  },
  {
    key: 'departmentName',
    name: 'Department',
    fieldName: 'departmentName',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'accessPolicy',
    name: 'Access Policy',
    fieldName: 'accessPolicy',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'invited',
    name: 'Invited',
    fieldName: 'invited',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    isVisible: false,
  },
  {
    key: 'rank',
    name: 'Rank',
    fieldName: 'rank',
    minWidth: 100,
    maxWidth: 120,
    ...commonColumnProps,
  },
  {
    key: 'securityLevel',
    name: 'Security Level',
    fieldName: 'securityLevel',
    minWidth: 80,
    maxWidth: 90,
    ...commonColumnProps,
    styles: centerAlignHeaderStyle,
    isVisible: false,
  },
  {
    key: 'inviteStatus',
    name: 'Status',
    fieldName: 'inviteStatus',
    minWidth: 80,
    maxWidth: 80,
    ...commonColumnProps,
  },
  {
    key: 'profileIconType',
    name: 'Access Status',
    fieldName: 'profileIconType',
    minWidth: 100,
    maxWidth: 100,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: '_lastUsedDate',
    name: 'Last Sign-In',
    fieldName: '_lastUsedDate',
    minWidth: 150,
    maxWidth: 150,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: '_activeApprovalCount',
    name: 'Active Approvals',
    fieldName: '_activeApprovalCount',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
    columnActionsMode: ColumnActionsMode.disabled,
  },
  {
    key: 'approvalStatus',
    name: 'Approval Status',
    fieldName: 'approvalStatus',
    minWidth: 120,
    maxWidth: 120,
    ...commonColumnProps,
  },
];
