import { IDropdownOption } from '@fluentui/react';
import { getFormattedAmountValue } from './components/AmountView/utils';
import Decimal from 'decimal.js';
import _ from 'lodash';
import { IconState } from './types/globalTypes';

const SPLIT_QUOTES_REGEX = /[^\s"]+|"([^"]*)"/g;
const trimDoubleQuotes = (input: string) => input.replace(/^"+|"+$/g, '');

export const splitOnQuotes = (input: string): string[] =>
  input
    .match(SPLIT_QUOTES_REGEX)
    ?.map((str) => trimDoubleQuotes(str).trim())
    .filter((str) => !!str) ?? [];
/**
 *
 * @param dropdownOptions List of options that need to be converted to IDropdown Array
 * @param textName The key to use for rendering the dropdown visible items
 * @param uid The key to be used for generating  keys for the dropdown items
 * @param allRequired determines whether or not needs an additional item for representing all
 * @returns IDropdown Array for it to be used inside a Fluent Dropdown
 */

export const formatDropdownOptions = <T>(
  data: T[] | undefined,
  options: {
    getKey: (item: T) => string | number;
    getText: (item: T) => string;
    getIsDisabled?: (item: T) => boolean;
    includeAll?: boolean;
    multiSelect?: boolean;
  }
) => {
  const optionList: IDropdownOption<T>[] =
    data?.map((item) => ({
      key: options.getKey(item),
      text: options.getText(item),
      disabled: options.getIsDisabled ? options.getIsDisabled(item) : false,
      multiSelect: options.multiSelect ? options.multiSelect : false,
    })) || [];
  return [
    ...(options.includeAll ? [{ key: -1, text: 'All' }] : []),
    ...optionList,
  ];
};

export const getOverageInfo = (
  controlTotalAmount: string | null | undefined,
  budgetAmount: string | null | undefined
) => {
  const overageAmount = new Decimal(controlTotalAmount || 0)
    .minus(budgetAmount || '0')
    .toNumber();
  const budgetAmountText = `Budget: ${
    budgetAmount ? getFormattedAmountValue(budgetAmount) : ''
  }`;
  const overageAmountText =
    overageAmount > 0
      ? ` Overage: ${getFormattedAmountValue(overageAmount.toFixed(2))}`
      : '';
  const toolTipContent = `${budgetAmountText}${overageAmountText}`;
  return toolTipContent;
};

export const doArraysHaveDifferentValues = (
  array1: string[],
  array2: string[]
) => {
  // Use _.difference to find the values that are unique to each array
  const diff1 = _.difference(array1, array2);
  const diff2 = _.difference(array2, array1);

  // Check if both differences are empty, meaning arrays have the same values
  const haveDifferentValues = diff1.length > 0 || diff2.length > 0;

  return haveDifferentValues;
};

export const getAcessStatusColor = (
  accessStatus: IconState | undefined | null
) => {
  switch (accessStatus) {
    case IconState.USER_INVALID:
    case IconState.USER_DEACTIVATED:
    case IconState.USER_AMENDED:
      return 'RED';
    case IconState.USER_ACTIVE:
    case IconState.USER_WAITING_AUTHENTICATION:
      return 'GREEN';
    default:
      return 'BLUE';
  }
};
