import { Stack, Text, IComboBoxOption } from '@fluentui/react';
import { FieldArray, useFormikContext } from 'formik';
import React from 'react';
import { FormikComboBox } from 'common/components';
import { ApprovalTypeRowValues, UserSetupValues } from '../types';
import { APPROVAL_TYPE_VALUES } from '../constant';
import { UserSetupCommonData_approvalTypes_nodes } from 'settings/account/userSetup/__generated__/UserSetupCommonData';
import { RemoveRowButton } from 'common/components/Buttons';
import { useStyles } from './index.styles';
import clsx from 'clsx';
import { GrantsCallout } from './GrantsCallout';

interface ApprovalTypeViewProps {
  deleteRow: (id: string, _rowTimestamp: string) => void;
  approvalTypes: UserSetupCommonData_approvalTypes_nodes[];
  inputsDisabled: boolean;
}
export const ApprovalTypeView: React.FC<ApprovalTypeViewProps> = ({
  deleteRow,
  approvalTypes,
  inputsDisabled,
}) => {
  const { values } = useFormikContext<UserSetupValues>();
  return (
    <Stack grow tokens={{ childrenGap: 25 }}>
      <Text variant="xLarge">User Permissions</Text>
      <Stack grow tokens={{ childrenGap: 10 }}>
        <FieldArray name="userApprovalGrants">
          {({ remove, push }) => (
            <>
              {values.userApprovalGrants?.map((item, index) => {
                const isLast =
                  !!values.userApprovalGrants &&
                  index === values.userApprovalGrants.length - 1;
                // generate available options from currently unselected types
                const approvalTypeOptions: IComboBoxOption[] = approvalTypes
                  .filter(
                    (type) =>
                      type.id === item.approvalTypeId ||
                      !values.userApprovalGrants?.some(
                        (row) => row.approvalTypeId === type.id
                      )
                  )
                  .map((type) => ({
                    key: type.id,
                    text: type.approvalType || '',
                  }));

                return (
                  <ApprovalTypeRow
                    key={index}
                    approvalTypeOptions={approvalTypeOptions}
                    isApprovalTypeSelected={!!item.approvalTypeId}
                    name={`userApprovalGrants[${index}]`}
                    remove={() => {
                      remove(index);
                      if (item.id) deleteRow(item.id, item._rowTimestamp!);
                    }}
                    removeState={
                      isLast || inputsDisabled
                        ? 'hidden'
                        : !item._isDeletable
                        ? 'disabled'
                        : undefined
                    }
                    onChange={() => {
                      if (isLast) push(APPROVAL_TYPE_VALUES);
                    }}
                    inputsDisabled={inputsDisabled}
                    data={item}
                  />
                );
              })}
            </>
          )}
        </FieldArray>
      </Stack>
    </Stack>
  );
};

interface ApprovalTypeRowProps {
  name: string;
  approvalTypeOptions: IComboBoxOption[];
  isApprovalTypeSelected: boolean;
  inputsDisabled: boolean;
  remove: () => void;
  removeState?: 'disabled' | 'hidden';
  onChange: () => void;
  data: ApprovalTypeRowValues;
}
export const GRANTS_VALUES = {
  isApprover: 'Approver',
  isRequester: 'Requester',
  isAddingNonApproversAllowed: 'Add Approvers',
  isApprovalRevokingEnabled: 'Amend',
  isApprovalTreeViewerAllowed: 'View Hierarchy',
  isAutoApproveEnabled: 'Auto Approve',
  isEnMasseApprovalsEnabled: 'En Masse Approve',
  isExtendedApprover: 'Notes/Attachments',
  isLowerTreeLevelBypassEnabled: 'Bypass Levels',
  isSelfApproveEnabled: 'Self Approve',
};

const ApprovalTypeRow: React.FC<ApprovalTypeRowProps> = ({
  name,
  approvalTypeOptions,
  isApprovalTypeSelected,
  inputsDisabled,
  remove,
  removeState,
  onChange,
  data,
}) => {
  const styles = useStyles();

  return (
    <Stack horizontal grow tokens={{ childrenGap: 20 }}>
      <Stack.Item grow={1}>
        <FormikComboBox
          useComboBoxAsMenuWidth
          placeholder="Approval Type"
          options={approvalTypeOptions}
          name={`${name}.approvalTypeId`}
          scrollSelectedToTop
          onChange={onChange}
          disabled={inputsDisabled}
        />
      </Stack.Item>
      <Stack horizontal grow={3} tokens={{ childrenGap: 5 }}>
        <Stack.Item grow>
          <GrantsCallout
            grantsData={data}
            name={name}
            onChange={onChange}
            disabled={inputsDisabled || !isApprovalTypeSelected}
          />
        </Stack.Item>
        <RemoveRowButton
          className={clsx(removeState === 'hidden' && styles.hide)}
          disabled={removeState === 'disabled'}
          onClick={remove}
        />
      </Stack>
    </Stack>
  );
};
