import { IDropdownOption } from '@fluentui/react';
import { FilterArrayType } from 'common/components/Filters';
import { TravelAuthorizationsOrderBy } from 'common/types/globalTypes';
import { OrderDirection, SortOrder } from 'common/utils/commonTypes';
import { ToNullable } from 'utility/types';
import { TravelAuthorizationSearchFilterTotalsVariables } from './__generated__/TravelAuthorizationSearchFilterTotals';

type OrderLookup = {
  [direction in OrderDirection]: TravelAuthorizationsOrderBy[];
};

const orderByMap = new Map<string, OrderLookup>([
  [
    'travelAuthorizationNumber',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
      ],
    },
  ],
  [
    'comment',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy.COMMENT_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy.COMMENT_DESC],
    },
  ],
  [
    '_travelerReference',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy._TRAVELER_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy._TRAVELER_REFERENCE_DESC,
      ],
    },
  ],
  [
    'tripPurpose',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy.TRIP_PURPOSE_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy.TRIP_PURPOSE_DESC],
    },
  ],
  [
    '_suppliers',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy._SUPPLIERS_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy._SUPPLIERS_DESC],
    },
  ],
  [
    '_poStatus',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy._PO_STATUS_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy._PO_STATUS_DESC],
    },
  ],
  [
    'travelAuthorizationDate',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_DATE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_DATE_DESC,
      ],
    },
  ],
  [
    '_isCorporateTravelAuthorization',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy._IS_CORPORATE_TRAVEL_AUTHORIZATION_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy._IS_CORPORATE_TRAVEL_AUTHORIZATION_DESC,
      ],
    },
  ],
  [
    'department',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.COMPANY_DEPARTMENT_BY_DEPARTMENT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'businessUnit',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.COMPANY_BUSINESS_UNIT_BY_BUSINESS_UNIT_ID__NAME_DESC,
      ],
    },
  ],
  [
    'corporateWorkgroupName',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.CORPORATE_WORKGROUP_NAME_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.CORPORATE_WORKGROUP_NAME_DESC,
      ],
    },
  ],
  [
    'currency',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.COMPANY_CURRENCY_BY_CURRENCY_ID__ISO_CODE_DESC,
      ],
    },
  ],
  [
    'budgetAmount',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy.BUDGET_AMOUNT_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy.BUDGET_AMOUNT_DESC],
    },
  ],
  [
    'controlTotalAmount',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.CONTROL_TOTAL_AMOUNT_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.CONTROL_TOTAL_AMOUNT_DESC,
      ],
    },
  ],
  [
    '_accountingStampTransactionReference',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy._ACCOUNTING_STAMP_TRANSACTION_REFERENCE_DESC,
      ],
    },
  ],
  [
    'status',
    {
      [OrderDirection.ASC]: [
        TravelAuthorizationsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_ASC,
      ],
      [OrderDirection.DESC]: [
        TravelAuthorizationsOrderBy.STATUS_TYPE_BY_STATUS_TYPE_ID__STATUS_TYPE_DESC,
      ],
    },
  ],
  [
    '_createdDate',
    {
      [OrderDirection.ASC]: [TravelAuthorizationsOrderBy._CREATED_DATE_ASC],
      [OrderDirection.DESC]: [TravelAuthorizationsOrderBy._CREATED_DATE_DESC],
    },
  ],
]);

export const toOrderByVariable = (sort?: SortOrder) => {
  const orderByArray = [
    TravelAuthorizationsOrderBy.TRAVEL_AUTHORIZATION_NUMBER_DESC,
    TravelAuthorizationsOrderBy.PRIMARY_KEY_ASC,
  ];
  if (!sort) return orderByArray;
  const orderBy = orderByMap.get(sort.column)?.[sort.direction];

  return [
    ...(orderBy ? orderBy : []),
    TravelAuthorizationsOrderBy.PRIMARY_KEY_ASC,
  ];
};

export const toFilterVariable = (filterList: FilterArrayType[]) =>
  filterList.map((filter) => {
    switch (filter.filterKey) {
      case 'departmentId':
      case 'businessUnitId':
      case 'currencyId':
      case 'corporateWorkgroupId':
      case '_statusTypeId':
      case '_createdByUserid':
        return {
          [filter.filterKey]: {
            equalTo: filter.value,
          },
        };
      case '_isCorporateTravelAuthorization':
        return {
          _isCorporateTravelAuthorization: {
            equalTo: Boolean(filter.value),
          },
        };
      default:
        return undefined;
    }
  });

type searchTotalFilterType = ToNullable<
  Omit<
    TravelAuthorizationSearchFilterTotalsVariables,
    | 'isSigningSearch'
    | 'isHistorySearch'
    | 'invoiceSigningSearchSearchRequest'
    | 'isLinkSearch'
    | 'isSearchAllData'
  >
>;
export const getFilterParams = (searchFilters: FilterArrayType[]) => {
  const searchTotalFilterArray: searchTotalFilterType = {
    filterCurrencyId: undefined,
    filterIsCorporateOrigin: undefined,
    filterBusinessUnitId: undefined,
    filterDepartmentId: undefined,
    filterIsProductionOrigin: undefined,
    filterWorkgroupId: undefined,
    filterStatusTypeId: undefined,
    filterCreatedById: undefined,
  };
  searchFilters.forEach((curr) => {
    switch (curr.filterKey) {
      case 'businessUnitId':
        return (searchTotalFilterArray['filterBusinessUnitId'] =
          curr.value as GqlUUID | null);
      case 'departmentId':
        return (searchTotalFilterArray['filterDepartmentId'] =
          curr.value as GqlUUID | null);
      case 'corporateWorkgroupId':
        return (searchTotalFilterArray['filterWorkgroupId'] =
          curr.value as GqlUUID | null);
      case '_isCorporateTravelAuthorization':
        if (curr.value === 0)
          return (searchTotalFilterArray['filterIsProductionOrigin'] = true);
        else return (searchTotalFilterArray['filterIsCorporateOrigin'] = true);
      case '_statusTypeId':
        return (searchTotalFilterArray['filterStatusTypeId'] = curr.value as
          | number
          | null);
      case 'currencyId':
        return (searchTotalFilterArray['filterCurrencyId'] = curr.value as
          | number
          | null);
      case '_createdByUserid':
        return (searchTotalFilterArray['filterCreatedById'] = curr.value as
          | string
          | null);
      default:
        return searchTotalFilterArray;
    }
  });
  return searchTotalFilterArray;
};

export enum listTypeEnum {
  all = 'ALL_TRANSACTIONS',
  my = 'MY_TRANSACTIONS',
}

export const listTypeOptions: IDropdownOption[] = [
  {
    key: listTypeEnum.all,
    text: 'All Transactions',
  },
  {
    key: listTypeEnum.my,
    text: 'My Transactions',
  },
];
