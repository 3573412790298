import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import { BlockBlobClient } from '@azure/storage-blob';
import {
  CommandBarButton,
  DatePicker,
  DayOfWeek,
  PrimaryButton,
  Separator,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from '@fluentui/react';
import clsx from 'clsx';
import { ActionMessageModal } from 'common/components/ActionMessageModal';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { DocumentList } from 'common/components/DocumentList';
import DraggablePanel from 'common/components/DraggablePanel';
import { FooterActionBar } from 'common/components/FooterActionBar';
import { HierarchyModal } from 'common/components/HierarchyModal';
import { PanelHeader } from 'common/components/PanelHeader';
import { UnsavedIndicator } from 'common/components/UnsavedIndicator';
import { TABLE_ROWS } from 'common/constants';
import { OnDocumentUploadStatus } from 'common/graphql/__generated__/OnDocumentUploadStatus';
import { useCommonStyles } from 'common/styles';
import {
  ApprovalRequestInput,
  EntityDeleteInput,
  EntitySelectionInput,
  UploadStatusType,
  UserApprovalGrantInput,
  UserApprovalGrantsUpdateTypeInput,
  UserProfileApprovalRevokeInput,
  UserProfilesOrderBy,
} from 'common/types/globalTypes';
import { EntityType, PanelCommonProps } from 'common/types/utility';
import { dateFormat, onFormatDate } from 'common/utils/dateFormats';
import { Form, Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { loader } from 'graphql.macro';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Prompt, useHistory, useParams } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ApprovalHistory } from 'settings/account/userSetup/view/ApprovalHistory';
import {
  UserProfileDelete,
  UserProfileDeleteVariables,
} from '../list/__generated__/UserProfileDelete';
import {
  UserProfiles,
  UserProfilesVariables,
} from '../list/__generated__/UserProfiles';

import { UserSetupCommonData } from '../__generated__/UserSetupCommonData';
import { ActionsMenu } from './ActionMenu';
import { AddSignatureModal } from './AddSignatureModal';
import { AttachedDocumentsDataType, BasicForm } from './BasicForm';
import { UserDocuments } from './BasicForm/UserDocuments';
import { columns } from './column.data';
import { APPROVAL_TYPE_VALUES, USER_SETUP_INITIAL_VALUES } from './constant';
import { useStyles } from './index.styles';
import { ShimmerView } from './ShimmerView/ShimmerViews';
import { SignatureOptions, SignatureView } from './Signatures';
import { ApprovalTypeRowValues, UserGrants, UserSetupValues } from './types';
import { validationSchema } from './userSetupValidation';
import { UserSignature } from './UserSignature';
import { UserProfile, UserProfileVariables } from './__generated__/UserProfile';
import {
  UserProfileApprovalCreate,
  UserProfileApprovalCreateVariables,
} from './__generated__/UserProfileApprovalCreate';
import {
  UserProfileApprovalRevoke,
  UserProfileApprovalRevokeVariables,
} from './__generated__/UserProfileApprovalRevoke';
import {
  UserProfileCreate,
  UserProfileCreateVariables,
} from './__generated__/UserProfileCreate';
import {
  UserProfileUpdate,
  UserProfileUpdateVariables,
} from './__generated__/UserProfileUpdate';
import { UrgencyFolder } from './UrgencyFolder';
import { UrgencyToggle } from 'common/components/UrgencySelector/UrgencyToggle';
import {
  UserProfileInvitationUpdate,
  UserProfileInvitationUpdateVariables,
} from '../__generated__/UserProfileInvitationUpdate';
const GET_USERPROFILE_DETAILS = loader('./UserProfile.graphql');
const USERPROFILE_DELETE = loader('../list/UserProfileDelete.graphql');
const USER_PROFILES = loader('../list/UserProfiles.graphql');
const USER_PROFILE_APPROVAL_REVOKE = loader(
  './UserProfileApprovalRevoke.graphql'
);
const USER_PROFILE_APPROVAL_CREATE = loader(
  './UserProfileApprovalCreate.graphql'
);
const USER_TYPE_DATA = loader('../UserSetupCommonData.graphql');
const DOCUMENT_UPLOAD_STATUS = loader(
  '../../../../common/graphql/DocumentUploadStatusSubscription.graphql'
);
const USER_PROFILE_CREATE = loader('./UserProfileCreate.graphql');
const USER_PROFILE_UPDATE = loader('./UserProfileUpdate.graphql');
const USER_SETUP = loader('../list/UserProfiles.graphql');
const INVITE = loader('../UserProfileInvitationUpdate.graphql');

const CONFIRM_INVITE_DIALOG_TITLE = 'Are you sure you want to invite?';
const CONFIRM_INVITE_DIALOG_SUBTEXT =
  'The current user will be sent an invitation to use the application.';
const CONFIRM_AMEND_DIALOG_TITLE = 'Are you sure?';
const CONFIRM_AMEND_DIALOG_SUBTEXT =
  'This will remove the User setup from the approval cycle and require re-approval.';
const CONFIRM_REQUEST_DIALOG_TITLE =
  'Are you sure you want to request this user profile for approval?';
const CONFIRM_REQUEST_DIALOG_SUBTEXT =
  'Your user profile will be requested for approval.';
const FINALIZED_BY_DATE_TOOLTIP =
  'Automatically promotes to urgent notifications when not approved by this date';

export const UserSetupView: React.FC = () => {
  const history = useHistory();
  const commonStyles = useCommonStyles();
  const styles = useStyles();
  const { addToast, updateToast } = useToasts();
  const { userId } = useParams<{ userId: string | undefined }>();

  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [attachedDocuments, setAttachedDocuments] = useState<
    AttachedDocumentsDataType | undefined
  >();

  const isNew = !userId;
  const panelHeading = isNew ? 'Create User Profile' : 'Edit User Profile';
  const toBeDeletedApprovals: EntityDeleteInput[] = [];
  const [requestComment, setRequestComment] = React.useState<string>();
  const [requiredDate, setRequiredDate] = React.useState<string | undefined>();
  const [urgencyLevel, setUrgencyLevel] = React.useState<number>(1);
  const [signatureModalVisible, setSignatureModalVisible] = useState(false);
  const [isAmendDialogVisible, setIsAmendDialogVisible] = useState(false);
  const [isRequestDialogVisible, setIsRequestDialogVisible] = useState(false);
  const [isInviteDialogVisible, setIsInviteDialogVisible] = useState(false);
  const [signatureData, setSignatureData] = useState<
    SignatureOptions[] | undefined
  >([]);

  const { data: userDetailsData, loading: userDetailsLoading } = useQuery<
    UserProfile,
    UserProfileVariables
  >(GET_USERPROFILE_DETAILS, {
    fetchPolicy: 'network-only',
    variables: { id: userId! },
    skip: !userId,
  });
  const [userProfileCreate, { loading: userProfileCreateLoading }] =
    useMutation<UserProfileCreate, UserProfileCreateVariables>(
      USER_PROFILE_CREATE,
      { errorPolicy: 'all' }
    );
  const [userProfileUpdate, { loading: userProfileUpdateLoading }] =
    useMutation<UserProfileUpdate, UserProfileUpdateVariables>(
      USER_PROFILE_UPDATE,
      { errorPolicy: 'all' }
    );
  const [userProfileInvite, { loading: inviteUserProfileLoading }] =
    useMutation<
      UserProfileInvitationUpdate,
      UserProfileInvitationUpdateVariables
    >(INVITE, {
      errorPolicy: 'all',
    });

  const { data: userProfileTypeData } = useQuery<UserSetupCommonData>(
    USER_TYPE_DATA,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'cache-only',
    }
  );
  const [deleteUserProfile] = useMutation<
    UserProfileDelete,
    UserProfileDeleteVariables
  >(USERPROFILE_DELETE, {
    errorPolicy: 'all',
  });

  const [
    userProfileApprovalRevoke,
    { loading: userProfileApprovalRevokeLoading },
  ] = useMutation<
    UserProfileApprovalRevoke,
    UserProfileApprovalRevokeVariables
  >(USER_PROFILE_APPROVAL_REVOKE, { errorPolicy: 'all' });
  const [userProfileApprovalCreate, { loading: userProfileApprovalLoading }] =
    useMutation<UserProfileApprovalCreate, UserProfileApprovalCreateVariables>(
      USER_PROFILE_APPROVAL_CREATE,
      {
        errorPolicy: 'all',
        onCompleted: () => {
          setUrgencyLevel(1);
        },
      }
    );

  const _onRenderHeader = (dirty: boolean, isSubmitting: boolean) => {
    return (
      <PanelHeader
        hasHeaderText={false}
        onClose={() => {
          history.replace('/account-management/users');
          setIsOpen(true);
        }}
      >
        <Stack grow horizontal horizontalAlign="space-between">
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Text variant="xLarge">{panelHeading}</Text>
            {userDetailsData?.userProfile?.role?.name && !isNew && (
              <Text variant="xLarge" className={commonStyles.colorThemePrimary}>
                {userDetailsData?.userProfile?.role.name}
              </Text>
            )}
            <UnsavedIndicator
              visible={!isNew && !userDetailsLoading && dirty && !isSubmitting}
            />
          </Stack>
          {!isNew && (
            <Stack horizontal verticalAlign="center">
              {userDetailsData?.userProfile?.statusType?.statusType ===
                'Pending' && (
                <UrgencyFolder userDetailsData={userDetailsData} />
              )}
              <ActionMessageModal
                entityType={EntityType.User}
                disabled={!userDetailsData?.userProfile?._isDeletable}
                onConfirm={() => {
                  _deleteUserProfile();
                }}
              />
              {userDetailsData?.userProfile?.statusType?.statusType && (
                <Text
                  className={clsx(
                    styles.status,
                    commonStyles.colorThemePrimary
                  )}
                >
                  {`(${userDetailsData?.userProfile?.statusType?.statusType})`}
                </Text>
              )}
            </Stack>
          )}
        </Stack>
      </PanelHeader>
    );
  };
  const _deleteUserProfile = async () => {
    const { errors } = await deleteUserProfile({
      variables: {
        input: {
          entityDelete: [
            {
              id: userDetailsData?.userProfile?.id!,
              rowTimestamp: userDetailsData?.userProfile?._rowTimestamp!,
            },
          ],
        },
      },
      update: (cache, { data }) => {
        const deletedIds = data?.userProfileDelete?.deletedEntities?.map(
          (entity) => entity?.id
        );
        if (deletedIds) {
          const cacheData = cache.readQuery<
            UserProfiles,
            UserProfilesVariables
          >({
            query: USER_PROFILES,
          });
          const filteredList = cacheData?.userProfiles?.nodes.filter(
            (pay) => deletedIds.indexOf(pay.id) === -1
          );
          const newData: UserProfiles = {
            userProfiles: {
              nodes: filteredList!,
              pageInfo: cacheData?.userProfiles?.pageInfo!,
              totalCount:
                cacheData?.userProfiles?.totalCount! - deletedIds.length,
            },
          };
          cache.writeQuery<UserProfiles, UserProfilesVariables>({
            query: USER_PROFILES,
            data: newData,
          });
        }
      },
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      addToast('Record deleted Successfully', {
        appearance: 'success',
      });
      history.replace('/account-management/users');
    }
  };
  const onInvite = async () => {
    setIsInviteDialogVisible(false);

    const { errors } = await userProfileInvite({
      variables: {
        input: {
          userProfileId: [userDetailsData?.userProfile?.id!],
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USERPROFILE_DETAILS,
          variables: {
            id: userDetailsData?.userProfile?.id,
          },
        },
      ],
    });

    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('User profile invited successfully', {
        appearance: 'success',
      });
  };
  let initialValues: UserSetupValues = USER_SETUP_INITIAL_VALUES;
  const { userProfile } = { ...userDetailsData };
  const { nodes } = { ...userProfile?.userApprovalGrants };
  const isDisabled = userDetailsData
    ? !userDetailsData.userProfile?._isUpdatable
    : false;
  useEffect(() => {
    const { nodes } = { ...userProfile?.approvalHistorySignaturesByEntityId };
    if (!userDetailsLoading && nodes?.length) setSignatureData(nodes);
  }, [userProfile, userDetailsLoading]);

  const grantNodes = nodes?.map(
    ({ id, _rowTimestamp, approvalTypeId, _isDeletable, ...item }) => {
      return {
        id,
        _rowTimestamp,
        approvalTypeId,
        _isDeletable,
        grants: {
          ...item,
        } as UserGrants,
      } as ApprovalTypeRowValues;
    }
  );

  if (userProfile && !isNew) {
    if (userDetailsData?.userProfile?._isUpdatable) {
      initialValues = {
        ...userProfile,
        userApprovalGrants:
          nodes?.length! > 0
            ? [...grantNodes!, ...[APPROVAL_TYPE_VALUES]]
            : [APPROVAL_TYPE_VALUES],
      };
    } else {
      initialValues = {
        ...userDetailsData?.userProfile!,
        userApprovalGrants: [...grantNodes!],
      };
    }
  }

  const handleSubmit = async (values: UserSetupValues) => {
    let createApprovalGrants: UserApprovalGrantInput[] = [];
    if (isNew) {
      if (values.userApprovalGrants) {
        const entityDocuments = attachedDocuments?.attachedDocuments?.map(
          (item) => item.id
        );
        createApprovalGrants = values?.userApprovalGrants
          ?.filter((ele) => ele.approvalTypeId)
          .map((item) => {
            return {
              approvalTypeId: Number(item.approvalTypeId),
              isApprover: item.grants.isApprover,
              isRequester: item.grants.isRequester,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isExtendedApprover: item.grants.isExtendedApprover,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
            };
          });
        const inputVariables = {
          userProfile: {
            accessGroupId: values?.accessGroupId!,
            description: values?.description,
            communicationGroupId: values.communicationGroupId!,
            dataAccessPolicyId: values.dataAccessPolicyId!,
            departmentGroupId: values.departmentGroupId!,
            departmentId: values.departmentId!,
            departmentOccupationTemplateId:
              values.departmentOccupationTemplateId!,
            emailAccount: values.emailAccount!,
            name: values.name!,
            roleId: values.roleId!,
            tagGroupId: values.tagGroupId,
            userGroupId: values.userGroupId!,
            userOccupationTitleId: values.userOccupationTitleId!,
            secureRowLevelId: values.secureRowLevelId,
            userRankTypeId: values.userRankTypeId,
          },
          userApprovalGrants: createApprovalGrants,
          entityDocumentId: entityDocuments,
        };
        const { data, errors } = await userProfileCreate({
          variables: {
            input: inputVariables,
          },
          update: (cache, { data }) => {
            const cacheData = cache.readQuery<UserProfiles>({
              query: USER_PROFILES,
            });
            if (
              cacheData?.userProfiles &&
              data?.userProfileCreate?.userProfile?.id
            ) {
              const newData: UserProfiles = {
                userProfiles: {
                  ...cacheData?.userProfiles,
                  pageInfo: cacheData?.userProfiles?.pageInfo,
                  totalCount: cacheData?.userProfiles?.totalCount + 1,
                  nodes: [
                    data?.userProfileCreate?.userProfile,
                    ...cacheData?.userProfiles?.nodes,
                  ],
                },
              };
              cache.writeQuery<UserProfiles, UserProfilesVariables>({
                query: USER_PROFILES,
                data: newData,
              });
            }
          },
        });
        if (errors?.length)
          addToast(errors[0].message, {
            appearance: 'error',
          });
        else {
          if (data?.userProfileCreate?.userProfile?.id!) {
            values?.userApprovalGrants?.pop();
          }
          history.replace(
            `/account-management/users/user/${data?.userProfileCreate?.userProfile?.id}`
          );
          addToast('User profile Added successfully', {
            appearance: 'success',
          });
        }
      }
    } else {
      let updateApprovalGrants: UserApprovalGrantsUpdateTypeInput[] = [];
      values.userApprovalGrants?.map((item, index) => {
        if (index !== values.userApprovalGrants?.length! - 1) {
          // for skipping the last empty row
          if (item.id) {
            const grantObject = {
              id: item.id,
              rowTimestamp: item._rowTimestamp,
              userApprovalGrantPatch: {
                approvalTypeId: Number(item.approvalTypeId),
                isAddingNonApproversAllowed:
                  item.grants.isAddingNonApproversAllowed,
                isApprovalRevokingEnabled:
                  item.grants.isApprovalRevokingEnabled,
                isApprovalTreeViewerAllowed:
                  item.grants.isApprovalTreeViewerAllowed,
                isApprover: item.grants.isApprover,
                isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
                isEnMasseApprovalsEnabled:
                  item.grants.isEnMasseApprovalsEnabled,
                isExtendedApprover: item.grants.isExtendedApprover,
                isLowerTreeLevelBypassEnabled:
                  item.grants.isLowerTreeLevelBypassEnabled,
                isRequester: item.grants.isRequester,
                isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
              },
            };
            updateApprovalGrants.push(grantObject);
          } else {
            const grantObject = {
              approvalTypeId: Number(item.approvalTypeId),
              isAddingNonApproversAllowed:
                item.grants.isAddingNonApproversAllowed,
              isApprovalRevokingEnabled: item.grants.isApprovalRevokingEnabled,
              isApprovalTreeViewerAllowed:
                item.grants.isApprovalTreeViewerAllowed,
              isApprover: item.grants.isApprover,
              isAutoApproveEnabled: item.grants.isAutoApproveEnabled,
              isEnMasseApprovalsEnabled: item.grants.isEnMasseApprovalsEnabled,
              isExtendedApprover: item.grants.isExtendedApprover,
              isLowerTreeLevelBypassEnabled:
                item.grants.isLowerTreeLevelBypassEnabled,
              isRequester: item.grants.isRequester,
              isSelfApproveEnabled: item.grants.isSelfApproveEnabled,
            };
            createApprovalGrants.push(grantObject);
          }
        }
      });
      const { data, errors } = await userProfileUpdate({
        variables: {
          input: {
            id: userDetailsData?.userProfile?.id!,
            rowTimestamp: userDetailsData?.userProfile?._rowTimestamp!,
            userProfilePatch: {
              accessGroupId: values?.accessGroupId!,
              description: values?.description,
              communicationGroupId: values.communicationGroupId!,
              dataAccessPolicyId: values.dataAccessPolicyId!,
              departmentGroupId: values.departmentGroupId!,
              departmentId: values.departmentId!,
              departmentOccupationTemplateId:
                values.departmentOccupationTemplateId!,
              emailAccount: values.emailAccount!,
              name: values.name!,
              roleId: values.roleId!,
              tagGroupId: values.tagGroupId!,
              userGroupId: values.userGroupId!,
              userOccupationTitleId: values.userOccupationTitleId!,
              secureRowLevelId: values.secureRowLevelId!,
              userRankTypeId: values.userRankTypeId!,
            },
            userApprovalGrantsCreate: createApprovalGrants,
            userApprovalGrantsDelete: toBeDeletedApprovals,
            userApprovalGrantsUpdate: updateApprovalGrants,
          },
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: USER_SETUP,
            variables: {
              first: TABLE_ROWS,
              orderBy: [UserProfilesOrderBy.NAME_ASC],
            },
          },
        ],
      });
      if (errors?.length)
        addToast(errors[0].message, {
          appearance: 'error',
        });
      else {
        history.replace(
          `/account-management/users/user/${data?.userProfileUpdate?.userProfile?.id}`
        );
        addToast('User profile edited successfully', {
          appearance: 'success',
        });
      }
    }
  };
  const isLoading =
    userProfileApprovalLoading ||
    userProfileApprovalRevokeLoading ||
    inviteUserProfileLoading ||
    userProfileCreateLoading ||
    userProfileUpdateLoading;
  const onAmendProcess = async () => {
    setIsAmendDialogVisible(false);
    const inputVariables: UserProfileApprovalRevokeInput = {
      entityId: userDetailsData?.userProfile?.id!,
      rowTimestamp: userDetailsData?.userProfile?._rowTimestamp!,
    };
    const { errors } = await userProfileApprovalRevoke({
      variables: {
        input: inputVariables,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USERPROFILE_DETAILS,
          variables: {
            id: userDetailsData?.userProfile?.id,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else
      addToast('Approval amended successfully', {
        appearance: 'success',
      });
  };

  const onRequestProcess = async () => {
    setIsRequestDialogVisible(false);
    const inputVariables: ApprovalRequestInput = {
      entityId: userDetailsData?.userProfile?.id!,
      rowTimestamp: userDetailsData?.userProfile?._rowTimestamp!,
      comments: requestComment,
    };
    if (requiredDate) inputVariables.requiredDate = requiredDate;
    const { errors } = await userProfileApprovalCreate({
      variables: {
        input: {
          entityApproval: [inputVariables],
          urgencyLevel: urgencyLevel,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: GET_USERPROFILE_DETAILS,
          variables: {
            id: userDetailsData?.userProfile?.id!,
          },
        },
      ],
    });
    if (errors?.length)
      addToast(errors[0].message, {
        appearance: 'error',
      });
    else {
      setRequestComment('');
      setRequiredDate('');
      addToast('Request sent for approval', {
        appearance: 'success',
      });
    }
  };

  const client = useApolloClient();
  const signatureUpdatable =
    userDetailsData?.userProfile?._isUserSignatureUpdatable &&
    userDetailsData?.userProfile?._isUserSignatureRequired;
  const signatureViewable =
    userDetailsData?.userProfile?.signatureDocument !== null;

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validateOnMount
      // Instead of directly passing schema, we're going to do the validation ourselves so we can
      // pass in the approval array length as part of the context
      validate={(val) => {
        try {
          validateYupSchema(val, validationSchema, true, {
            approvalTypeCount: val.userApprovalGrants?.length,
          });
        } catch (err) {
          return yupToFormErrors(err);
        }
        return {};
      }}
      onSubmit={handleSubmit}
    >
      {({ submitForm, isSubmitting, dirty, errors }) => {
        return (
          <Form>
            <DraggablePanel
              {...PanelCommonProps}
              isOpen={isOpen}
              initialWidth={1000}
              minWidth={1000}
              isBlocking={isNew}
              onRenderFooter={() => (
                <FooterActionBar
                  isLoading={isLoading}
                  children={
                    userDetailsData &&
                    !isNew && (
                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        {userDetailsData?.userProfile?._isApprovalRevocable && (
                          <HierarchyModal
                            entityId={userDetailsData.userProfile?.id!}
                            isApprovalEntry={false}
                            customButton={
                              <CommandBarButton
                                iconProps={{ iconName: 'Relationship' }}
                                text="View Hierarchy"
                                styles={{ root: { height: 40 } }}
                              />
                            }
                          />
                        )}
                        {signatureViewable && (
                          <UserSignature
                            _downloadLink={
                              userDetailsData.userProfile?.signatureDocument
                                ?._downloadLink!
                            }
                          />
                        )}
                        {signatureUpdatable && (
                          <PrimaryButton
                            className={styles.disabledButton}
                            text="Signature"
                            iconProps={{ iconName: 'Add' }}
                            onClick={() => {
                              setSignatureModalVisible(true);
                            }}
                          />
                        )}

                        {userDetailsData.userProfile?._isStagedToInvite && (
                          <PrimaryButton
                            text="Invite"
                            onClick={() => setIsInviteDialogVisible(true)}
                          />
                        )}

                        {userDetailsData.userProfile
                          ?._isStagedApprovalRequest && (
                          <PrimaryButton
                            disabled={dirty}
                            className={styles.disabledButton}
                            text="Request Approval"
                            onClick={() => setIsRequestDialogVisible(true)}
                          />
                        )}
                        {userDetailsData.userProfile?._isApprovalRevocable && (
                          <PrimaryButton
                            disabled={dirty}
                            className={styles.disabledButton}
                            text="Amend User"
                            onClick={() => setIsAmendDialogVisible(true)}
                          />
                        )}
                      </Stack>
                    )
                  }
                  createNewText="New User"
                  addNewForm={() =>
                    history.replace('/account-management/users/user')
                  }
                  disabled={{
                    save: !dirty || Object.keys(errors).length > 0,
                    cancel: !dirty,
                  }}
                  isSubmitting={isSubmitting}
                  isCreate={!userId}
                  onCancel={() => history.replace('/account-management/users')}
                  onSave={async () => await submitForm()}
                />
              )}
              onRenderHeader={() => _onRenderHeader(dirty, isSubmitting)}
              isLightDismiss
            >
              {!isNew && userDetailsLoading ? (
                <ShimmerView />
              ) : (
                <Stack>
                  {isNew && (
                    <Stack>
                      <Stack
                        tokens={{ childrenGap: 10 }}
                        className={styles.mainContainer}
                      >
                        <Stack className={styles.documentsContainer}>
                          <UserDocuments
                            userProfileDetails={userDetailsData?.userProfile}
                            onDocumentsAttached={(documentsData) => {
                              if (documentsData) {
                                if (
                                  attachedDocuments?.attachedDocuments.length
                                ) {
                                  setAttachedDocuments({
                                    documentTypeId:
                                      documentsData.documentTypeId,
                                    entityDocumentId: [
                                      ...attachedDocuments.entityDocumentId!,
                                      ...documentsData.entityDocumentId!,
                                    ],
                                    attachedDocuments: [
                                      ...attachedDocuments.attachedDocuments,
                                      ...documentsData.attachedDocuments,
                                    ],
                                  });
                                } else {
                                  setAttachedDocuments(documentsData);
                                }
                              }
                            }}
                            prevSelectedDocuments={
                              attachedDocuments?.attachedDocuments
                            }
                          />
                          {attachedDocuments?.attachedDocuments.length && (
                            <Stack tokens={{ childrenGap: 10 }}>
                              <Text
                                variant="xLarge"
                                className={styles.marginB20}
                              >
                                Attached Documents
                              </Text>
                              <DocumentList
                                isNew={true}
                                columns={columns}
                                data={
                                  attachedDocuments?.attachedDocuments || []
                                }
                                isDeleteButtonVisible
                                onRemoveRow={(data) => {
                                  const tempObject = {
                                    documentTypeId:
                                      attachedDocuments.documentTypeId,
                                    entityDocumentId:
                                      attachedDocuments?.entityDocumentId
                                        ? attachedDocuments?.entityDocumentId.filter(
                                            (item) => item !== data.id
                                          )
                                        : [],
                                    attachedDocuments:
                                      attachedDocuments?.attachedDocuments
                                        ? attachedDocuments?.attachedDocuments.filter(
                                            (item) => item.id !== data.id
                                          )
                                        : [],
                                  };
                                  setAttachedDocuments(tempObject);
                                }}
                              />
                            </Stack>
                          )}
                        </Stack>
                      </Stack>
                      {attachedDocuments?.attachedDocuments.length && (
                        <Separator />
                      )}
                    </Stack>
                  )}
                  {!isNew && (
                    <Stack tokens={{ padding: '20px 0px 0px 0px' }}>
                      <Stack tokens={{ childrenGap: 10, padding: '0 25px' }}>
                        <Stack>
                          <ActionsMenu
                            userDetails={userDetailsData!}
                            secureRowLevels={
                              userProfileTypeData?.secureRowLevels
                            }
                            onUpload={async (
                              fileSelected,
                              document,
                              toastId
                            ) => {
                              const observer = client.subscribe({
                                query: DOCUMENT_UPLOAD_STATUS,
                                variables: {
                                  documentId: document._documentFileId!,
                                },
                              });

                              const subscription = observer.subscribe(
                                (data) => {
                                  const subscribedData =
                                    data.data as OnDocumentUploadStatus;

                                  const { status, document } = {
                                    ...subscribedData.documentUploadStatus,
                                  };

                                  if (
                                    status.type === UploadStatusType.VALIDATING
                                  ) {
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Validating files ${fileSelected.name} - ${status.message}`
                                        : `Validating files ${fileSelected.name}`,
                                      appearance: 'info',
                                      autoDismiss: false,
                                    });
                                  } else if (
                                    status.type === UploadStatusType.EXTRACTING
                                  ) {
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Extracting data from ${fileSelected.name} - ${status.message}`
                                        : `Extracting data from ${fileSelected.name}`,
                                      appearance: 'info',
                                      autoDismiss: false,
                                    });
                                  } else if (
                                    status.type === UploadStatusType.FAILURE
                                  ) {
                                    subscription.unsubscribe?.();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Upload of ${fileSelected.name} failed - ${status.message}`
                                        : `Upload of ${fileSelected.name} failed`,
                                      appearance: 'error',
                                      autoDismiss: true,
                                    });
                                  } else if (
                                    status.type === UploadStatusType.WARNING
                                  ) {
                                    subscription.unsubscribe?.();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Warning for file ${fileSelected.name}: ${status.message}`
                                        : `Warning for file ${fileSelected.name}`,
                                      appearance: 'warning',
                                      autoDismiss: true,
                                    });
                                  } else {
                                    subscription.unsubscribe?.();
                                    updateToast(toastId!, {
                                      content: status.message
                                        ? `Successfully uploaded ${fileSelected.name}: ${status.message}`
                                        : `Successfully uploaded ${fileSelected.name}`,
                                      appearance: 'success',
                                      autoDismiss: true,
                                    });

                                    if (userId) {
                                      const cacheData = client.readQuery<
                                        UserProfile,
                                        UserProfileVariables
                                      >({
                                        query: GET_USERPROFILE_DETAILS,
                                        variables: { id: userId },
                                      });
                                      if (document && cacheData?.userProfile) {
                                        const updatedData: UserProfile = {
                                          userProfile: {
                                            ...cacheData?.userProfile,
                                            entityDocumentsByEntityId: {
                                              ...cacheData?.userProfile
                                                ?.entityDocumentsByEntityId,
                                              nodes: [
                                                { ...document },
                                                ...cacheData?.userProfile
                                                  ?.entityDocumentsByEntityId
                                                  .nodes,
                                              ],
                                            },
                                          },
                                        };
                                        client.writeQuery<
                                          UserProfile,
                                          UserProfileVariables
                                        >({
                                          query: GET_USERPROFILE_DETAILS,
                                          data: updatedData,
                                          variables: { id: userId },
                                        });
                                      }
                                    }
                                  }
                                }
                              );
                            }}
                          />
                        </Stack>
                      </Stack>
                      {attachedDocuments?.attachedDocuments.length && (
                        <Separator />
                      )}
                    </Stack>
                  )}
                  <BasicForm
                    isTemplateControlled={
                      userDetailsData?.userProfile?.departmentOccupationTemplate
                        ?.isTemplateControlled
                    }
                    toDeleteRows={(id, _rowTimestamp) => {
                      const toDeleteRow: EntityDeleteInput = {
                        id: id,
                        rowTimestamp: _rowTimestamp!,
                      };
                      toBeDeletedApprovals.push(toDeleteRow);
                    }}
                    inputsDisabled={isDisabled}
                  />
                  {userDetailsData?.userProfile?.approvalHistoriesByEntityId &&
                    userDetailsData?.userProfile?.approvalHistoriesByEntityId
                      .nodes.length! > 0 &&
                    !isNew && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.approvalHistoryContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Approval History</Text>
                          <Text
                            variant="medium"
                            className={styles.requestedByText}
                          >
                            Requested By:
                          </Text>
                          <ApprovalHistory
                            data={
                              userDetailsData.userProfile
                                .approvalHistoriesByEntityId
                            }
                          />
                        </Stack>
                      </Stack>
                    )}
                  {userDetailsData?.userProfile
                    ?.approvalHistorySignaturesByEntityId.nodes.length! > 0 &&
                    !isNew && (
                      <Stack>
                        <Separator />
                        <Stack
                          className={styles.tagsContainer}
                          tokens={{ childrenGap: 20 }}
                        >
                          <Text variant="xLarge">Signatures</Text>
                          <SignatureView signatureData={signatureData!} />
                        </Stack>
                      </Stack>
                    )}
                  <Prompt
                    when={dirty && !isSubmitting}
                    message="Are you sure you want to leave your changes unsaved?"
                  />
                </Stack>
              )}
              <ConfirmDialog
                hidden={!isInviteDialogVisible}
                title={CONFIRM_INVITE_DIALOG_TITLE}
                subText={CONFIRM_INVITE_DIALOG_SUBTEXT}
                onDismiss={() => setIsInviteDialogVisible(false)}
                onConfirm={onInvite}
              />
            </DraggablePanel>

            <AddSignatureModal
              userDetails={userDetailsData!}
              onDismiss={() => setSignatureModalVisible(false)}
              isModalVisible={signatureModalVisible}
              onSignatureUpload={async (data, toastId, imageUrl) => {
                const { uploadLink, document } = {
                  ...data?.userSignatureUploadDocument,
                };

                if (data) {
                  const observer = client.subscribe({
                    query: DOCUMENT_UPLOAD_STATUS,
                    variables: {
                      documentId: document._documentFileId!,
                    },
                  });

                  const subscription = observer.subscribe((data) => {
                    const subscribedData = data.data as OnDocumentUploadStatus;

                    const { status, document } = {
                      ...subscribedData.documentUploadStatus,
                    };

                    if (status.type === UploadStatusType.VALIDATING) {
                      updateToast(toastId, {
                        content: ` Validating file for Signature`,
                        appearance: 'info',
                        autoDismiss: false,
                      });
                    } else if (status.type === UploadStatusType.FAILURE) {
                      subscription.unsubscribe?.();
                      updateToast(toastId!, {
                        content: `Signature Upload Failed ${
                          status.message ? status.message : ''
                        }`,
                        appearance: 'error',
                        autoDismiss: true,
                      });
                    } else {
                      subscription.unsubscribe?.();

                      updateToast(toastId, {
                        content: ` Success file for Signature`,
                        appearance: 'success',
                        autoDismiss: true,
                      });

                      const cacheData = client.readQuery<
                        UserProfile,
                        UserProfileVariables
                      >({
                        query: GET_USERPROFILE_DETAILS,
                        variables: { id: userId! },
                      });

                      const updatedData: UserProfile = {
                        userProfile: {
                          ...cacheData?.userProfile!,
                          entityDocumentsByEntityId: {
                            ...cacheData?.userProfile
                              ?.entityDocumentsByEntityId,
                            nodes: [
                              { ...document! },
                              ...cacheData?.userProfile
                                ?.entityDocumentsByEntityId.nodes!,
                            ],
                          },
                        },
                      };

                      client.writeQuery<UserProfile, UserProfileVariables>({
                        query: GET_USERPROFILE_DETAILS,
                        data: updatedData,
                        variables: { id: userId! },
                      });
                    }
                  });

                  const clientBlob = new BlockBlobClient(uploadLink!);
                  const response = await fetch(imageUrl!);
                  const blob = await response.blob();

                  await clientBlob.uploadData(blob);

                  updateToast(toastId!, {
                    content: 'Validating Signature Document',
                  });
                }
              }}
            />
            <ConfirmDialog
              isAmendButton
              hidden={!isAmendDialogVisible}
              title={CONFIRM_AMEND_DIALOG_TITLE}
              subText={CONFIRM_AMEND_DIALOG_SUBTEXT}
              onDismiss={() => setIsAmendDialogVisible(false)}
              onConfirm={onAmendProcess}
            />
            <ConfirmDialog
              hidden={!isRequestDialogVisible}
              title={CONFIRM_REQUEST_DIALOG_TITLE}
              subText={CONFIRM_REQUEST_DIALOG_SUBTEXT}
              onDismiss={() => setIsRequestDialogVisible(false)}
              minWidth={500}
              onConfirm={onRequestProcess}
            >
              <Stack tokens={{ childrenGap: 10 }}>
                <TextField
                  className={styles.marginT10}
                  multiline
                  rows={3}
                  value={requestComment}
                  placeholder="Please write your comment here (optional)"
                  resizable={false}
                  onChange={(_event, value) => setRequestComment(value || '')}
                />
                <TooltipHost content={FINALIZED_BY_DATE_TOOLTIP}>
                  <DatePicker
                    minDate={new Date()}
                    firstDayOfWeek={DayOfWeek.Sunday}
                    placeholder="Finalized by date (optional)"
                    ariaLabel="Date"
                    formatDate={onFormatDate}
                    showWeekNumbers
                    firstWeekOfYear={1}
                    showMonthPickerAsOverlay
                    showGoToToday={false}
                    onSelectDate={(date) =>
                      setRequiredDate(dateFormat(date!.toString()))
                    }
                  />
                </TooltipHost>
                <Stack tokens={{ padding: '6px 0px 0px 0px' }}>
                  <UrgencyToggle
                    onToggle={(data) => {
                      setUrgencyLevel(data ? 0 : 1);
                    }}
                  />
                </Stack>
              </Stack>
            </ConfirmDialog>
          </Form>
        );
      }}
    </Formik>
  );
};
