import { getTheme, IButtonStyles } from '@fluentui/react';
import { Colors } from 'common/types/globalTypes';

export const getToggleStyles = (color: string | undefined) => {
  const theme = getTheme();
  const getBackgroundColor = () => {
    switch (color) {
      case Colors.YELLOW:
        return theme.palette.yellow;
      case Colors.RED:
        return theme.palette.red;
      case Colors.GREEN:
        return theme.palette.greenLight;
      case Colors.CYAN:
        return theme.palette.blueLight;
      case Colors.MAGENTA:
        return theme.palette.magentaLight;
      case 'DISABLED':
        return theme.palette.neutralTertiaryAlt;
      default:
        return theme.palette.themePrimary;
    }
  };
  const getColor = () => {
    switch (color) {
      case Colors.YELLOW:
        return '#423000';
      case Colors.RED:
        return 'white';
      case Colors.GREEN:
        return theme.palette.greenDark;
      case Colors.CYAN:
        return theme.palette.themeDarker;
      case Colors.MAGENTA:
        return 'white';
      default:
        return 'white';
    }
  };
  const getFocusColor = () => {
    switch (color) {
      case Colors.YELLOW:
        return theme.palette.yellowDark;
      case Colors.RED:
        return theme.palette.redDark;
      case Colors.GREEN:
        return theme.palette.green;
      case Colors.CYAN:
        return '#05aefc';
      case Colors.MAGENTA:
        return theme.palette.magenta;
      default:
        return theme.palette.themeDark;
    }
  };

  const styles: IButtonStyles = {
    rootPressed: {
      borderColor: getFocusColor(),
      background: getFocusColor(),
    },
    rootHovered: {
      borderColor: getFocusColor(),
      background: getFocusColor(),
    },
    iconHovered: {
      color: theme.palette.white,
    },
    root: [
      {
        background: getBackgroundColor(),
        borderColor: getBackgroundColor(),
        color: getColor(),
      },
    ],
  };

  return styles;
};

const getButtonStylesCustom = (color: string): IButtonStyles => {
  const width = 100;
  const styles: { [key: string]: IButtonStyles } = {
    GREEN: {
      root: {
        backgroundColor: '#008000', 
        color: 'white',
        border: '1px solid #006400', 
        width,
      },
      rootHovered: {
        backgroundColor: '#006400', 
        color: 'white',
      },
      rootPressed: {
        backgroundColor: '#004d00', 
        color: 'white',
      },
    },
    RED: {
      root: {
        backgroundColor: '#FF0000', 
        color: 'white',
        border: '1px solid #B22222', 
        width,
      },
      rootHovered: {
        backgroundColor: '#B22222', 
        color: 'white',
      },
      rootPressed: {
        backgroundColor: '#800000', 
      },
    },
    BLUE: {
      root: {
        backgroundColor: '#0000FF', 
        color: 'white',
        border: '1px solid #00008B', 
        width,
      },
      rootHovered: {
        backgroundColor: '#00008B', 
        color: 'white',
      },
      rootPressed: {
        backgroundColor: '#000066', 
        color: 'white',
      },
    },
  };

  return styles[color];
};

export default getButtonStylesCustom;
