import { useMutation, useQuery } from '@apollo/client';
import {
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField,
} from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import { loader } from 'graphql.macro';
import React, { useState } from 'react';
import { useToasts } from 'react-toast-notifications';
import { ApprovalHistoryBranches_approvalHistoryBranches_nodes } from '../__generated__/ApprovalHistoryBranches';
import {
  ReplaceEntityApprover,
  ReplaceEntityApproverVariables,
} from '../__generated__/ReplaceEntityApprover';
import {
  AvailableEntityApproverList,
  AvailableEntityApproverListVariables,
} from './__generated__/AvailableEntityApproverList';
import { useStyles } from './index.styles';

const AVAILABLE_APPROVER_LIST = loader('./AvailableEntityApproverList.graphql');
const REPLACE_APPROVER = loader('../ReplaceEntityApprover.graphql');
const APPROVAL_HOSTORY_BRANCHES = loader('../ApprovalHistoryBranches.graphql');
const GET_APPROVAL_HISTORY_DETAILS = loader('../../ApprovalHistory.graphql');

interface ReplaceApproverModalProps {
  onDismiss: () => void;
  entityApprovalId: string | undefined;
  entityItem: ApprovalHistoryBranches_approvalHistoryBranches_nodes | undefined;
  removeApprover: boolean;
}
export const ReplaceApproverModal: React.FC<ReplaceApproverModalProps> = ({
  onDismiss,
  entityApprovalId,
  entityItem,
  removeApprover,
}) => {
  const styles = useStyles();
  const [newApprover, setNewApprover] = useState<string | undefined>(undefined);
  const [reason, setReason] = useState<string | undefined>(undefined);
  const { addToast } = useToasts();
  const { data: availableApproversList } = useQuery<
    AvailableEntityApproverList,
    AvailableEntityApproverListVariables
  >(AVAILABLE_APPROVER_LIST, {
    variables: {
      entityApprovalId: entityApprovalId!,
    },
    skip: !entityApprovalId,
  });
  const approverListOptions: IDropdownOption[] =
    availableApproversList?.availableEntityApproverList?.nodes?.map((item) => ({
      key: item.id,
      text: item.user?.name ?? '',
    })) || [];
  const [replaceApprover, { loading: loadingReplaceApprover }] = useMutation<
    ReplaceEntityApprover,
    ReplaceEntityApproverVariables
  >(REPLACE_APPROVER, { errorPolicy: 'all' });

  const _onConfirm = async () => {
    const { errors } = await replaceApprover({
      variables: {
        input: {
          entityBranchId: entityItem?.id!,
          rowTimestamp: entityItem?._rowTimestamp!,
          originalUserProfileId: entityItem?.approverUserProfileId!,
          isBypassOnly: removeApprover,
          reason: reason!,
          newUserProfileId: removeApprover ? null : newApprover,
        },
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: APPROVAL_HOSTORY_BRANCHES,
          variables: {
            id: entityApprovalId!,
          },
        },
        {
          query: GET_APPROVAL_HISTORY_DETAILS,
          variables: {
            id: entityApprovalId!,
          },
        },
      ],
    });
    if (errors?.length) {
      addToast(errors[0].message, {
        appearance: 'error',
      });
    } else {
      onDismiss();
      addToast(
        `${removeApprover ? 'Bypassed' : 'Replaced'} the approver successfully`,
        {
          appearance: 'success',
        }
      );
    }
  };
  const disableConfirmButton =
    !reason ||
    loadingReplaceApprover ||
    (removeApprover ? false : !newApprover);
  return (
    <ConfirmDialog
      isConfirmPrimaryButton
      successBtnDisable={disableConfirmButton}
      hidden={false}
      title={`${removeApprover ? 'Bypass Approver' : 'Replace Approver'} - ${
        entityItem?.approverName
      }`}
      onDismiss={onDismiss}
      minWidth={450}
      onConfirm={_onConfirm}
    >
      <Stack tokens={{ childrenGap: '10px', padding: '20px 0px 20px 0px' }}>
        {!removeApprover && (
          <Stack horizontal tokens={{ childrenGap: 20 }}>
            <Label className={styles.width100}>New Approver </Label>
            <Dropdown
              placeholder="Select"
              className={styles.dropdownStyles}
              options={approverListOptions}
              onChange={(_, option) => {
                setNewApprover(option?.key as string);
              }}
            />
          </Stack>
        )}
        <Stack horizontal tokens={{ childrenGap: 20 }}>
          <Label className={styles.width100}>
            Reason for {removeApprover ? 'Bypass' : 'Replacement'}
          </Label>
          <TextField
            className={styles.textInputStyle}
            multiline
            rows={3}
            value={reason}
            placeholder="Please write your reason here"
            resizable={false}
            onChange={(_event, value) => setReason(value || '')}
          />
        </Stack>
      </Stack>
    </ConfirmDialog>
  );
};
