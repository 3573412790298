import { Stack } from '@fluentui/react';
import React from 'react';
import { ApprovalHistoryItem } from '..';
import { BypassApprover } from './BypassApprover';
import { ReplaceApprover } from './ReplaceApprover';
import { branchUserList_branchUserList_nodes } from '../__generated__/branchUserList';

export type ManageApproversProps = {
  selectedRows: ApprovalHistoryItem[];
  approverUser: branchUserList_branchUserList_nodes | null;
  onSuccess: () => void;
};
export const ManageApprovers: React.FC<ManageApproversProps> = (props) => {
  return (
    <Stack>
      {!!props.selectedRows.length && props.approverUser && (
        <Stack tokens={{ childrenGap: 20 }} horizontal verticalAlign="center">
          <ReplaceApprover {...props} />
          <BypassApprover {...props} />
        </Stack>
      )}
    </Stack>
  );
};
