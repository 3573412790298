import { MessageBar, MessageBarType, Stack, Text } from '@fluentui/react';
import { ConfirmDialog } from 'common/components/ConfirmDialog';
import React from 'react';
import { ReplaceApproverApprovals } from '../__generated__/ReplaceApproverApprovals';

export type ExceptionModalProps = {
  showErrorResponse: boolean;
  onDismiss?: () => void;
  response: ReplaceApproverApprovals | null | undefined;
};

export const ExceptionModal: React.FC<ExceptionModalProps> = (props) => {
  return (
    <>
      {props.showErrorResponse && (
        <ConfirmDialog
          hideCancelButton
          confirmText="OK"
          successBtnDisable={false}
          hidden={false}
          title={`Exceptions:`}
          onDismiss={() => {
            // setShowErrorResponse(false);
            props.onDismiss?.();
          }}
          severityType={'failed'}
          minWidth={600}
          onConfirm={() => {
            // setShowErrorResponse(false);
            props.onDismiss?.();
          }}
        >
          <Stack tokens={{ childrenGap: '10px', padding: '20px 0px 20px 0px' }}>
            {props.response?.replaceApproverApprovals?.processRequests?.map(
              (item) => {
                return (
                  <Stack>
                    <MessageBar messageBarType={MessageBarType.error}>
                      <Text>
                        {item?.reference}
                        {'\n'}
                      </Text>
                      <Text>{item?.message}</Text>
                    </MessageBar>
                  </Stack>
                );
              }
            )}
          </Stack>
        </ConfirmDialog>
      )}
    </>
  );
};
