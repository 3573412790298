import {
  Callout,
  DirectionalHint,
  IComboBoxOption,
  SelectableOptionMenuItemType,
  Stack,
  Text,
} from '@fluentui/react';
import clsx from 'clsx';
import { FormHookComboBox } from 'common/components/FormHooksFields/FormHookComboBox';
import { useCommonStyles } from 'common/styles';
import React, { useState } from 'react';
import {
  ApprovalTypeRowValues,
  UserGrants,
} from 'settings/account/userSetup/view/types';
import { CalloutText } from './CalloutText';
import { useStyles } from './index.styles';
const _ = require('lodash');

interface UserGrantOption extends IComboBoxOption {
  key: keyof UserGrants | 'selectAll';
}
const USER_GRANT_OPTIONS: UserGrantOption[] = [
  {
    key: 'selectAll',
    text: 'Select All',
    itemType: SelectableOptionMenuItemType.SelectAll,
  },
  { key: 'isApprover', text: 'Approver' },
  { key: 'isRequester', text: 'Requester' },
  { key: 'isApprovalTreeViewerAllowed', text: 'View Hierarchy' },
  { key: 'isAddingNonApproversAllowed', text: 'Add Approvers' },
  { key: 'isApprovalRevokingEnabled', text: 'Amend' },
  { key: 'isLowerTreeLevelBypassEnabled', text: 'Bypass Levels' },
  { key: 'isSelfApproveEnabled', text: 'Self Approve' },
  { key: 'isAutoApproveEnabled', text: 'Auto Approve' },
  { key: 'isEnMasseApprovalsEnabled', text: 'En Masse Approve' },
  { key: 'isExtendedApprover', text: 'Notes/Attachments' },
];

export const GRANTS_VALUES = {
  isApprover: 'Approver',
  isRequester: 'Requester',
  isAddingNonApproversAllowed: 'Add Approvers',
  isApprovalRevokingEnabled: 'Amend',
  isApprovalTreeViewerAllowed: 'View Hierarchy',
  isAutoApproveEnabled: 'Auto Approve',
  isEnMasseApprovalsEnabled: 'En Masse Approve',
  isLowerTreeLevelBypassEnabled: 'Bypass Levels',
  isSelfApproveEnabled: 'Self Approve',
  isExtendedApprover: 'Notes/Attachments',
};
interface DocumentCalloutProps {
  grantsData: ApprovalTypeRowValues;
  name: string;
  index: number;
  onChange: () => void;
  disabled: boolean;
}

export const GrantsCallout: React.FC<DocumentCalloutProps> = ({
  grantsData,
  name,
  index,
  onChange,
  disabled,
}) => {
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const calloutId = `callOutId_GrantView_${grantsData.id}`;
  const getValuesFromKeys = (array: string[]) => {
    let valuesArray = [];
    for (let [key, value] of Object.entries(GRANTS_VALUES)) {
      if (array.includes(key)) valuesArray.push(value);
    }
    return valuesArray;
  };
  var selectedMetric = _.keys(_.pickBy(grantsData.grants));
  var selectedMetricString = getValuesFromKeys(selectedMetric);
  return (
    <Stack
      verticalAlign="center"
      id={calloutId}
      onMouseEnter={() => setIsCalloutVisible(true)}
      onMouseLeave={() => setIsCalloutVisible(false)}
    >
      <FormHookComboBox
        name={`${name}.grants`}
        placeholder="Grants"
        options={USER_GRANT_OPTIONS}
        multiSelect
        onChange={onChange}
        useComboBoxAsMenuWidth
      />

      {isCalloutVisible && selectedMetricString.length > 0 && disabled && (
        <Callout
          className={styles.callout}
          gapSpace={0}
          target={`#${calloutId}`}
          directionalHint={DirectionalHint.topCenter}
          onDismiss={() => setIsCalloutVisible(false)}
          setInitialFocus
        >
          <>
            <Stack className={styles.typeContainer}>
              <Text
                className={clsx(commonStyles.semibold, styles.categoryType)}
                variant="mediumPlus"
              >
                Grants
              </Text>
            </Stack>
            <Stack>
              {selectedMetricString.map((value, index) => {
                return <CalloutText key={index} text={value} hasSeparator />;
              })}
            </Stack>
          </>
        </Callout>
      )}
    </Stack>
  );
};
